import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from '../initialState';
// Types
import { ReduxState } from '../../core/types/ReduxStoreTypes';
// API Wrappers
import addEnquiryLead from '../api/addEnquiryLead';
import checkDuplicateAccount from '../api/checkDuplicateAccount';
import generateOTP from '../api/generateOTP';
import verifyEtvExist from '../api/verifyEtvExist';
import checkOTP from '../api/checkOTP';
import updateLead from '../api/updateLead';
import getAdditionalInfoFields from '../api/getAdditionalInfoFields';
import addRegistrationLead from '../api/addRegistrationLead';
import getComplianceAndAccountStatus from '../api/getComplianceAndAccountStatus';
import saveDocuments from '../api/saveDocuments';
import mobileDownloadSMS from '../api/mobileDownloadSMS';
import checkDuplicateAccountAdvanced from '../api/checkDuplicateAccountAdvanced';
import generateSDKToken from '../api/generateSDKToken';
import createBiometricCheck from '../api/createBiometricCheck';
import getCustomerDetailsByEmailToken from '../api/getCustomerDetailsByEmailToken';
// Constants
import { USA_COR_ETV_DEFAULT_VALUE } from '../../core/utils/Constants/Constants';
import { RESET_REGISTRATION_DETAILS } from '../ActionTypeConstants';

export const resetRegistrationDetails = createAction<string>(RESET_REGISTRATION_DETAILS);

const registrationDetailsSlice = createSlice({
  name: 'registrationDetails',
  initialState: initialState.registrationDetails,
  reducers: {
    addRegistrationsDetails(state, action) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.referred = action.payload.referred;
      state.referralCodeOrName = action.payload.referralCodeOrName;
    },
    updateCountryCode(state, action) {
      state.countryCode = action.payload.countryCode;
    },
    addMobileNumber(state, action) {
      state.countryCode = action.payload.countryCode;
      state.mobileNumber = action.payload.mobileNumber;
    },
    addSecurityPin(state, action) {
      state.securityPin = action.payload;
    },
    resetDuplicateAccount(state) {
      state.isDuplicateAccount = false;
      state.isDuplicateAccountLoading = 'idle';
    },
    resetDuplicateAccountAdvance(state) {
      state.isDuplicateActAdvance = false;
      state.isDuplicateActAdvanceLoading = 'idle';
    },
    resetLeadId(state) {
      state.leadId = '';
      state.enquiryLeadLoading = 'idle';
    },
    resetLoadingValue(state, action) {
      state.loading = action.payload;
    },
    resetOtpValidation(state, action) {
      console.log('resetOtpValidation', action);
      state.otpValidationError = action.payload;
    },
    resetOTPValidationValue(state) {
      const { loading, isValidOTP, generateOtpSuccess, updateLeadStatus, updateLeadLoading } =
        initialState.registrationDetails;

      state.loading = loading;
      state.isValidOTP = isValidOTP;
      state.generateOtpSuccess = generateOtpSuccess;
      state.updateLeadLoading = updateLeadLoading;
      state.updateLeadStatus = updateLeadStatus;
    },
    addEnterDetails(state, action) {
      const {
        countryOfResidence,
        countryOfResidenceName,
        address,
        dob,
        occupation,
        usa,
        esp,
        agreeTC,
        employmentStatus,
        employmentIndustry
      } = action.payload;

      state.countryOfResidence = countryOfResidence;
      state.countryOfResidenceName = countryOfResidenceName;
      state.address = address;
      state.dob = {
        day: dob.day,
        month: dob.month,
        year: dob.year
      };
      state.occupation = occupation;
      state.employmentStatus = employmentStatus;
      state.employmentIndustry = employmentIndustry;
      state.usa = usa;
      state.esp = esp;
      state.agreeTC = agreeTC;
    },
    addCountryOfResidence(state, action) {
      const { countryOfResidence, countryOfResidenceName } = action.payload;
      state.countryOfResidence = countryOfResidence;
      state.countryOfResidenceName = countryOfResidenceName;
    },
    addstateOfCountryOfResidence(state, action) {
      const { stateOfCountryOfResidence } = action.payload;
      state.stateOfCountryOfResidence = stateOfCountryOfResidence;
    },
    addPreferanceData(state, action) {
      const { currencyFrom, currencyTo, amount } = action.payload;
      state.currencyFrom = currencyFrom;
      state.currencyTo = currencyTo;
      state.amount = amount;
      state.agreeTC = true;
    },
    resetRegistrationAPILoadingState(state) {
      state.registrationLoading = 'idle';
      state.addRegistrationLeadInProgress = false;
    },
    startRegistrationProcess(state) {
      state.addRegistrationLeadInProgress = true;
    },
    resetAddressData(state) {
      state.address = initialState.registrationDetails.address;
    },
    setPoaView(state) {
      state.isPoaRequired = true;
    },
    setPoiView(state) {
      state.isPoiRequired = true;
    },
    resetPoaPoiView(state) {
      state.isPoiRequired = initialState.registrationDetails.isPoiRequired;
      state.isPoaRequired = initialState.registrationDetails.isPoaRequired;
    },
    resetUpdateLeadLoading(state) {
      state.updateLeadLoading = initialState.registrationDetails.updateLeadLoading;
    },
    setQueryCORValue(state, action) {
      state.queryCOR = action.payload;
    },
    resetVerifyETVLoading(state) {
      state.verifyETVLoading = 'idle';
    },
    setOnfidoView(state) {
      state.isOnfidoRequired = true;
    },
    resetOnfidoStates(state) {
      state.generateSDKTokenLoading = 'idle';
      state.createBiometricCheckLoading = 'idle';
      state.sdkToken = '';
      state.biometricId = '';
      state.verifyIdentityBtnLoading = false;
    },
    setHideMobileHeaderState(state) {
      state.shouldShowMobileHeader = false;
    },
    resetMobileHeaderState(state) {
      state.shouldShowMobileHeader = true;
    },
    setHideFullLayoutFooterState(state) {
      state.shouldShowFullLayoutFooter = false;
    },
    resetFullLayoutFooterState(state) {
      state.shouldShowFullLayoutFooter = true;
    },
    setHideBurgerMenu(state) {
      state.shouldHideBurgerMenu = true;
    },
    resetBurgerMenuState(state) {
      state.shouldHideBurgerMenu = false;
    },
    setHideLiveChatIcon(state) {
      state.shouldHideLiveChatIon = true;
    },
    resetLiveChatIconState(state) {
      state.shouldHideLiveChatIon = false;
    },
    resetComplianceStatusLoading(state) {
      state.complianceStatusLoading = 'idle';
    },
    updateZarModalOpenStatus(state, action) {
      state.hasZARModalBeenOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Check Duplicate Account
    builder
      .addCase(resetRegistrationDetails, () => initialState.registrationDetails)

      .addCase(checkDuplicateAccount.pending, (state) => {
        state.isDuplicateAccountLoading = 'loading';
      })
      .addCase(checkDuplicateAccount.fulfilled, (state, { payload }) => {
        state.isDuplicateAccount = Object.values(payload?.data).includes('EXISTS_IN_DB');
        state.isDuplicateAccountLoading = 'succeeded';
      })
      .addCase(checkDuplicateAccount.rejected, (state, action) => {
        state.isDuplicateAccountLoading = 'failed';
        state.error = action.error.message;
      });

    // Add Enquiry Lead
    builder
      .addCase(addEnquiryLead.pending, (state) => {
        state.enquiryLeadLoading = 'loading';
      })
      .addCase(addEnquiryLead.fulfilled, (state, { payload }) => {
        state.enquiryLeadLoading = 'succeeded';
        state.leadId = payload.data.leadId;
      })
      .addCase(addEnquiryLead.rejected, (state, action) => {
        state.enquiryLeadLoading = 'failed';
        state.error = action.error.message;
        console.log('addEnquiryLead API response : ', 'Error! Invalid Request');
      });

    // Generate OTP
    builder
      .addCase(generateOTP.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(generateOTP.fulfilled, (state, { payload }) => {
        state.loading = 'succeeded';
        state.generateOtpSuccess = payload.data.generateOtpSuccess;
        state.mobileScreenError = '';
      })
      .addCase(generateOTP.rejected, (state, action) => {
        state.loading = 'failed';
        state.mobileScreenError = 'Error! Invalid Request';
        console.log('generateOTP API response : ', 'Error! Invalid Request');
      });

    // Verify ETV Exist
    builder
      .addCase(verifyEtvExist.pending, (state) => {
        state.loading = 'loading';
        state.verifyETVLoading = 'loading';
      })
      .addCase(verifyEtvExist.fulfilled, (state, { payload }) => {
        const { currencySelling, estTransactionValue, isETVExist, currencyBuying, corIsUSA } = payload.data;
        state.loading = 'succeeded';
        state.verifyETVLoading = 'succeeded';
        state.isETVExist = isETVExist;
        if (isETVExist) {
          state.currencyFrom = currencySelling;
          state.currencyTo = currencyBuying;
          state.amount = estTransactionValue;
        } else if (corIsUSA) {
          // Check if country is USA. If yes and
          // since ETV doesn't exists set default value for
          // currencyFrom currencyTo and amount and make ETC exists to true.
          state.isETVExist = true;
          state.currencyFrom = USA_COR_ETV_DEFAULT_VALUE.currencyFrom;
          state.currencyTo = USA_COR_ETV_DEFAULT_VALUE.currencyTo;
          state.amount = USA_COR_ETV_DEFAULT_VALUE.amount;
        }
      })
      .addCase(verifyEtvExist.rejected, (state, action) => {
        state.loading = 'failed';
        state.verifyETVLoading = 'failed';
        state.error = action.error.message;
        console.log('checkEtvExist API response : ', 'Error! Invalid Request');
      });

    // Check OTP
    builder
      .addCase(checkOTP.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(checkOTP.fulfilled, (state, { payload }) => {
        state.loading = 'succeeded';
        state.isValidOTP = payload.data.checkOTPResponse;
        state.otpResponseMessage = payload.data.responseMessage;
        state.otpValidationError = !payload.data.checkOTPResponse ? payload.data.responseMessage : '';
        console.log('Check otp Valid API response : ', payload.data.responseMessage);
      })
      .addCase(checkOTP.rejected, (state, action) => {
        state.loading = 'failed';
        state.otpValidationError = 'Error! Invalid Request';
        console.log('Check otp Valid API response : ', 'Error! Invalid Request');
      });

    // Update Lead
    builder
      .addCase(updateLead.pending, (state) => {
        state.updateLeadLoading = 'loading';
      })
      .addCase(updateLead.fulfilled, (state, { payload }) => {
        state.updateLeadLoading = 'succeeded';
        state.updateLeadStatus = payload.data.updateLeadStatus;
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.updateLeadLoading = 'failed';
        state.error = action.error.message;
        console.log('sendForUpdateLead API response : ', 'Error! Invalid Request');
      });

    // Additional info field
    builder
      .addCase(getAdditionalInfoFields.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getAdditionalInfoFields.fulfilled, (state, { payload }) => {
        state.loading = 'succeeded';
        state.additionalInfo = payload.data.additionalInfo;
      })
      .addCase(getAdditionalInfoFields.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });

    // checkDuplicateAccountAdvanced API
    builder
      .addCase(checkDuplicateAccountAdvanced.pending, (state) => {
        state.isDuplicateActAdvanceLoading = 'loading';
      })
      .addCase(checkDuplicateAccountAdvanced.fulfilled, (state, { payload }) => {
        state.isDuplicateActAdvanceLoading = 'succeeded';
        state.isDuplicateActAdvance = Object.values(payload?.data).includes('EXISTS_IN_SF');
      })
      .addCase(checkDuplicateAccountAdvanced.rejected, (state, action) => {
        state.isDuplicateActAdvanceLoading = 'failed';
      });

    // addRegistrationLead API
    builder
      .addCase(addRegistrationLead.pending, (state) => {
        state.registrationLoading = 'loading';
      })
      .addCase(addRegistrationLead.fulfilled, (state, { payload }) => {
        const { crmAccountID, crmContactID, organizationLegalEntity, tradeAccNo } = payload.data;
        state.registrationLoading = 'succeeded';
        state.crmAccountID = crmAccountID;
        state.crmContactID = crmContactID;
        state.organizationLegalEntity = organizationLegalEntity;
        state.tradeAccNo = tradeAccNo;
      })
      .addCase(addRegistrationLead.rejected, (state, { error, payload }: any) => {
        state.registrationLoading = 'failed';
        state.error = error.message;
        state.statusCode = payload.statusCode.toString();
      });

    // getComplianceAndAccountStatus API
    builder
      .addCase(getComplianceAndAccountStatus.pending, (state) => {
        state.complianceStatusLoading = 'loading';
      })
      .addCase(getComplianceAndAccountStatus.fulfilled, (state, { payload }) => {
        if (payload) {
          const { complianceStatus, complianceCode } = payload.data;
          state.complianceStatusLoading = 'succeeded';
          state.isComplianceStatusComplete =
            complianceStatus === 'ACTIVE' &&
            (complianceCode === '' || complianceCode === '000' || complianceCode === '913');
          state.complianceStatus = complianceStatus;
          state.complianceCode = complianceCode;
        }
      })
      .addCase(getComplianceAndAccountStatus.rejected, (state, action) => {
        state.complianceStatusLoading = 'failed';
        state.error = action.error.message;
        console.log('getComplianceAndAccountStatus API response : ', 'Error! Invalid Request');
      });

    // saveDocuments API
    builder
      .addCase(saveDocuments.pending, (state) => {
        state.saveDocumentLoading = 'loading';
      })
      .addCase(saveDocuments.fulfilled, (state, { payload }) => {
        const { UploadDocumentStatus } = payload.data;
        state.saveDocumentLoading = 'succeeded';
        state.uploadDocumentStatus = UploadDocumentStatus;
      })
      .addCase(saveDocuments.rejected, (state, action) => {
        state.saveDocumentLoading = 'failed';
        state.error = action.error.message;
      });

    // mobileDownloadSms API
    builder
      .addCase(mobileDownloadSMS.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(mobileDownloadSMS.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(mobileDownloadSMS.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });

    // generateSDKToken API
    builder
      .addCase(generateSDKToken.pending, (state) => {
        state.generateSDKTokenLoading = 'loading';
        state.verifyIdentityBtnLoading = true;
      })
      .addCase(generateSDKToken.fulfilled, (state, { payload }) => {
        const { token } = payload;
        state.generateSDKTokenLoading = 'succeeded';
        state.sdkToken = token;
        state.verifyIdentityBtnLoading = false;
      })
      .addCase(generateSDKToken.rejected, (state, action) => {
        state.generateSDKTokenLoading = 'failed';
        state.error = action.error.message;
        state.verifyIdentityBtnLoading = false;
      });

    // createBiometricCheck API
    builder
      .addCase(createBiometricCheck.pending, (state) => {
        state.createBiometricCheckLoading = 'loading';
      })
      .addCase(createBiometricCheck.fulfilled, (state, { payload }) => {
        const { id } = payload;
        state.createBiometricCheckLoading = 'succeeded';
        state.biometricId = id;
      })
      .addCase(createBiometricCheck.rejected, (state, action) => {
        state.createBiometricCheckLoading = 'failed';
        state.error = action.error.message;
      });

    // getCustomerDetailsByEmailToken API
    builder
      .addCase(getCustomerDetailsByEmailToken.pending, (state) => {
        state.complianceStatusLoading = 'loading';
      })
      .addCase(getCustomerDetailsByEmailToken.fulfilled, (state, { payload }) => {
        if (payload) {
          const {
            complianceStatusCode,
            complianceStatus,
            crmAccountId,
            crmContactId,
            firstName,
            lastName,
            countryOfResidenceISOCode,
            emailAddress,
            mobileNumber,
            isdCode,
            tradeAccountNumber,
            // orgCode,
            // source,
            legalEntity
          } = payload;

          state.complianceStatusLoading = 'succeeded';

          /*state.isComplianceStatusComplete =
            complianceStatus === 'ACTIVE' && (complianceStatusCode === '' || complianceStatusCode === '000' || complianceStatusCode === '913');*/

          state.complianceStatus = complianceStatus;
          state.complianceCode = complianceStatusCode;

          // state.complianceCode = '919';
          // state.complianceCode = '910';
          // state.complianceStatus = 'INACTIVE';

          state.crmAccountID = crmAccountId;
          state.crmContactID = crmContactId;
          state.email = emailAddress;
          state.organizationLegalEntity = legalEntity;
          state.firstName = firstName;
          state.lastName = lastName;
          state.countryOfResidence = countryOfResidenceISOCode;
          state.tradeAccNo = tradeAccountNumber;
          state.mobileNumber = mobileNumber;
          state.countryCode = `+${isdCode}`;
        }
      })
      .addCase(getCustomerDetailsByEmailToken.rejected, (state, action) => {
        state.complianceStatusLoading = 'failed';
        state.error = action.error.message;
        console.log('getCustomerDetailsByEmailToken API response : ', 'Error! Invalid Request');
      });
  }
});

export const getRegistrationDetails = (state: ReduxState) => state.registrationDetails;

export const {
  addRegistrationsDetails,
  updateCountryCode,
  addMobileNumber,
  addSecurityPin,
  resetDuplicateAccount,
  resetLeadId,
  resetLoadingValue,
  resetOtpValidation,
  resetOTPValidationValue,
  addEnterDetails,
  addCountryOfResidence,
  addstateOfCountryOfResidence,
  resetDuplicateAccountAdvance,
  addPreferanceData,
  startRegistrationProcess,
  resetRegistrationAPILoadingState,
  resetAddressData,
  setPoaView,
  setPoiView,
  resetPoaPoiView,
  resetUpdateLeadLoading,
  setQueryCORValue,
  resetVerifyETVLoading,
  setOnfidoView,
  resetOnfidoStates,
  setHideMobileHeaderState,
  resetMobileHeaderState,
  setHideFullLayoutFooterState,
  resetFullLayoutFooterState,
  setHideBurgerMenu,
  resetBurgerMenuState,
  setHideLiveChatIcon,
  resetLiveChatIconState,
  resetComplianceStatusLoading,
  updateZarModalOpenStatus
} = registrationDetailsSlice.actions;

export default registrationDetailsSlice.reducer;