import { memo, useEffect, useState, useMemo, useContext, FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { List, ListItem, ListItemIcon, ListItemText, ListItemIconProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// Contexts
import { LayoutContext, LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPLink from '../../atoms/RPLink';
import RPText from '../../atoms/RPText';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import {
  LiveChatConfig,
  LiveChatRouteOffsetType,
  RouteLiveChatConfigType,
  liveChatConfigType
} from '../../../core/types/LiveChatTypes';
//RTK Slice
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
import { getAuthorisationDetails } from '../../../redux/modules/authorisationDetailsSlice';
// Utils
import {
  getBody1BoldStyles,
  getBody1MediumStyles,
  getBody2NormalStyles,
  getBody2MediumStyles,
  getBodySmallNormalStyles,
  getBodyExtraSmallNormalStyles,
  getBodySmallBoldStyles
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { initESW } from '../../../core/utils/InitLiveChat';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getLiveChatConfig } from '../../../core/utils/GetOrganizationSpecificLiveChatConfig';
// Constants
import {
  COLOUR_TEXT_BLACK,
  LIVE_CHAT_TIME_TEXT_AVAILABILITY_ORGANIZATIONS_LIST,
  ROUTES_LIVE_CHAT_MAPPING
} from '../../../core/utils/Constants/Constants';

interface RPHelpSectionProps {
  isLivechatEnabled: boolean;
}

interface StyledRPHelpSectionProps extends LayoutProps {
  helpOffset: number;
  shouldShowLiveChatIcon: boolean;
  isHelpOffSetApplicable: boolean;
}

interface ChatButtonWrapperProps extends LayoutProps {
  helpOffset: number;
  isHelpOffSetApplicable: boolean;
}
interface ContentSectionProps extends LayoutProps {
  showHelpSection: boolean;
  helpOffset: number;
  isHelpOffSetApplicable: boolean;
}
interface HeaderProps extends RPHelpSectionProps {}

const StyledRPHelpSection = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'layout' && prop !== 'helpOffset' && prop !== 'shouldShowLiveChatIcon' && prop !== 'isHelpOffSetApplicable'
})<StyledRPHelpSectionProps>(({ helpOffset, layout, shouldShowLiveChatIcon, isHelpOffSetApplicable }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  //275 is the width of the help section which should be subtracted to calculate the offset from the left.
  const defaultLeftOffsetValue: number = helpOffset - 275;
  //10px from left added in desktop view to maintain 30px from right.
  const buttonLeftOffsetValue: number = isDesktopLayout ? defaultLeftOffsetValue + 10 : defaultLeftOffsetValue;

  return {
    visibility: shouldShowLiveChatIcon ? 'visible' : 'hidden',
    position: 'fixed',
    zIndex: '9999',
    width: '275px',
    height: 'auto',
    ...(isHelpOffSetApplicable && {
      left: buttonLeftOffsetValue
    }),
    bottom: isMobileLayout ? '88px' : '98px',
    right: !isHelpOffSetApplicable ? '40px' : isMobileLayout ? '20px' : '30px'
  };
});

const ChatButtonWrapper = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'layout' && prop !== 'helpOffset' && prop !== 'isHelpOffSetApplicable'
})<ChatButtonWrapperProps>(({ theme, layout, helpOffset, isHelpOffSetApplicable }) => {
  const {
    colours: {
      buttons: { secondary: secondaryButton }
    }
  } = theme;

  const COLOUR_BUTTON_SECONDARY_BG: string = secondaryButton.bg;
  const COLOUR_BUTTON_SECONDARY_BORDER: string = secondaryButton.border;

  const isDesktopLayout: boolean = layout === 'desktop';

  //48 is the width of the help section which should be subtracted to calculate the offset from the left.
  const defaultLeftOffsetValue: number = helpOffset - 48;
  //10px from left added in desktop view to maintain 30px from right.
  const buttonLeftOffsetValue: number = isDesktopLayout ? defaultLeftOffsetValue + 10 : defaultLeftOffsetValue;

  return {
    width: '48px',
    height: '48px',
    position: 'fixed',
    ...(isHelpOffSetApplicable
      ? {
          left: buttonLeftOffsetValue
        }
      : {
          right: '40px'
        }),

    bottom: '20px',
    background: COLOUR_BUTTON_SECONDARY_BG,
    border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
    '&:hover': {
      background: COLOUR_BUTTON_SECONDARY_BG,
      border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`
    }
  };
});

const ContentSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layout' && prop !== 'helpOffset' && prop !== 'isHelpOffSetApplicable' && prop !== 'showHelpSection'
})<ContentSectionProps>(({ theme, layout, showHelpSection, helpOffset, isHelpOffSetApplicable }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt, background, backgroundSecondary, backgroundAltInverseStrong },
      buttons: { outline: outlineButton, primary: primaryButton, secondary: secondaryButton },
      text: { textWeak, textInputLabel, textInputDisabled, textInverse, textWeakest },
      borders: { borderInput, borderSelectionHover }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isDesktopLayout: boolean = layout === 'desktop';

  //275 is the width of the content section which should be subtracted to calculate the offset from the left.
  const defaultLeftOffsetValue: number = helpOffset - 275;
  //10px from left added in desktop view to maintain 30px from right.
  const buttonLeftOffsetValue: number = isDesktopLayout ? defaultLeftOffsetValue + 10 : defaultLeftOffsetValue;

  const COLOUR_BG_BACKGROUND_ALT: string = backgroundAlt;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_STRONG: string = backgroundAltInverseStrong;
  const COLOUR_BG_BACKGROUND: string = background;
  const COLOUR_BG_BACKGROUND_SECONDARY: string = backgroundSecondary;
  const COLOUR_BUTTON_OUTLINE_BG: string = outlineButton.bg;
  const COLOUR_BUTTON_OUTLINE_TEXT: string = outlineButton.text;
  const COLOUR_BUTTON_OUTLINE_BORDER: string = outlineButton.border;

  const COLOUR_BUTTON_PRIMARY_BG: string = primaryButton.bg;
  const COLOUR_BUTTON_PRIMARY_TEXT: string = primaryButton.text;
  const COLOUR_BUTTON_PRIMARY_BORDER: string = primaryButton.border;

  const COLOUR_BUTTON_SECONDARY_BG: string = secondaryButton.bg;
  const COLOUR_BUTTON_SECONDARY_TEXT: string = secondaryButton.text;
  const COLOUR_BUTTON_SECONDARY_BORDER: string = secondaryButton.border;

  const COLOUR_TEXT_TEXT_INPUT_LABEL: string = textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: string = textInputDisabled;
  const COLOUR_TEXT_TEXT_INVERSE: string = textInverse;
  const COLOUR_TEXT_TEXT_WEAK: string = textWeak;
  const COLOUR_TEXT_TEXT_WEAKEST: string = textWeakest;
  const COLOUR_BORDER_BORDER_INPUT: string = borderInput;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: string = borderSelectionHover;

  return {
    display: showHelpSection ? 'block' : 'none',
    padding: '0px 0px',
    position: 'relative',
    boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',

    // Chat button styling
    '.embeddedServiceHelpButton': {
      position: 'unset !important',
      '.helpButton': {
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        height: '40px',
        '.helpButtonEnabled, .helpButtonDisabled': {
          '.embeddedServiceIcon': {
            display: 'none !important'
          },
          '&:hover::before, &:focus::before': {
            backgroundColor: `${COLOUR_BUTTON_SECONDARY_BG} !important`,
            borderRadius: '23px'
          }
        },
        '.uiButton': {
          ...getBody2MediumStyles(theme),
          backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
          border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
          color: COLOUR_BUTTON_SECONDARY_TEXT,
          fontFamily: theme.typography.fontFamily,
          cursor: 'pointer !important',
          borderRadius: '100px',
          minWidth: '211px',
          maxWidth: '211px',
          height: '40px',
          padding: '8px 20px',
          boxShadow: 'none !important',
          '&.helpButtonDisabled': {
            cursor: 'not-allowed !important',
            backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
            opacity: 0.5
          },
          '.helpButtonLabel': {
            textDecoration: 'none'
          }
        }
      }
    },

    // chat button minimise state styling
    '.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI': {
      position: 'relative',
      top: 0,
      left: 0,
      cursor: 'pointer !important',
      borderRadius: '23px',
      backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
      border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
      color: COLOUR_BUTTON_SECONDARY_TEXT,
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      height: '40px',
      width: '211px',
      padding: '8px 20px',
      boxShadow: 'none !important',

      '.minimizedText>.message': {
        ...getBody2MediumStyles(theme),
        color: COLOUR_TEXT_TEXT_INVERSE
      },
      ':hover': {
        backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
        border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
        color: COLOUR_BUTTON_SECONDARY_TEXT
      },
      ':focus': {
        backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
        border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
        color: COLOUR_BUTTON_SECONDARY_TEXT,
        textDecoration: 'none'
      },
      ...(!isDesktopLayout && {
        '.embeddedServiceLoadingBalls': {
          display: 'flex',
          alignItems: 'center'
        }
      }),
      '.content': {
        messageContent: {
          '.embeddedServiceLoadingBalls': {
            display: 'flex',
            alignItems: 'center',
            '.loadingBall': {
              margin: '2px!important',
              background: '#fff!important'
            }
          }
        }
      }
    },
    '.embeddedServiceSidebarMinimizedDefaultUI:not(.helpButton) .minimizedText': {
      maxWidth: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 'normal',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block'
    },
    '.embeddedServiceSidebarMinimizedDefaultUI:not(.waiting) .messageContent': {
      margin: '1px',
      display: 'flex',
      flexDirection: 'row'
    },
    '.embeddedServiceSidebar.modalContainer': {
      position: 'relative !important'
    },
    '.embeddedServiceSidebar.sidebarMaximized': {
      background: 'none !important'
    },
    // Chat window header styling
    '.sidebarHeader': {
      backgroundColor: `${COLOUR_BG_BACKGROUND_SECONDARY} !important`,
      h2: {
        ...getBody1BoldStyles(theme),
        fontFamily: theme.typography.fontFamily,
        color: COLOUR_TEXT_TEXT_INVERSE
      },
      button: {
        height: '30px !important',
        minHeight: '30px !important',
        width: '30px !important',
        minWidth: '30px !important',
        '&:hover[embeddedService-chatHeader_chatHeader]:before': {
          top: '15px !important',
          width: '30px !important',
          height: '30px !important'
        }
      },
      '.minimizeButton': {
        svg: {
          height: '15px',
          width: '15px',
          position: 'absolute',
          right: '53px',
          top: '16px'
        }
      },
      '.closeButton': {
        svg: {
          height: '15px',
          width: '15px'
        }
      }
    },
    // announcement styling
    '.announcement': {
      ...getBodySmallNormalStyles(theme),
      fontFamily: theme.typography.fontFamily,
      background: `${COLOUR_BG_BACKGROUND} !important`,
      height: '50px',
      padding: '14px 54px 14px 54px'
    },
    // LoadingBalls styling
    '.embeddedServiceSidebar.layout-docked': {
      '.dockableContainer': {
        bottom: isMobileLayout ? '88px' : '98px',
        position: 'fixed',
        top: 'auto',
        ...(isHelpOffSetApplicable
          ? {
              left: buttonLeftOffsetValue
            }
          : {
              left: 'auto',
              right: '40px'
            }),
        borderRadius: '8px 8px 8px 8px',
        width: '275px !important',
        maxWidth: 'none !important',
        height: '483px !important',
        maxHeight: 'none !important',
        margin: '0px'
      },
      '.waitingStateContent': {
        padding: '40px 24px',
        ' .embeddedServiceLoadingBalls': {
          paddingTop: '0px !important',
          '.loadingBall': {
            margin: '2px !important',
            background: `${COLOUR_BG_BACKGROUND_SECONDARY} !important`
          }
        }
      },

      //Dialog text styling
      '.embeddedServiceLiveAgentStateChat': {
        '.chatSessionStartTime': {
          ...getBodyExtraSmallNormalStyles(theme),
          fontFamily: theme.typography.fontFamily,
          padding: '20px 24px',
          margin: '0px'
        },
        '.messageArea': {
          '.messageWrapper': {
            'li.ended': {
              '.uiOutputRichText': {
                ...getBodySmallBoldStyles(theme),
                fontFamily: theme.typography.fontFamily
              }
            }
          },
          ':focus': {
            border: 'none'
          }
        }
      },

      // Chat input styling
      '.embeddedServiceLiveAgentStateChatItem': {
        marginBottom: '0px',
        padding: '0px 24px 20px 24px !important',
        '.nameAndTimeContent': {
          ...getBodyExtraSmallNormalStyles(theme),
          fontFamily: theme.typography.fontFamily,
          color: COLOUR_TEXT_TEXT_INPUT_LABEL,
          marginTop: '5px'
        }
      },
      '.embeddedServiceLiveAgentStateChatMessage': {
        '.eventMessage': {
          margin: '0px'
        },
        '.eventMessage:after, .eventMessage:before': {
          borderTop: `1px dashed ${COLOUR_TEXT_TEXT_INPUT_DISABLED}`
        },
        '.uiOutputRichText': {
          ...getBodySmallNormalStyles(theme),
          fontFamily: theme.typography.fontFamily
        }
      },
      '.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent': {
        marginLeft: '45px !important',
        padding: '10px 12px !important'
      },
      '.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent': {
        background: `${COLOUR_BG_BACKGROUND_ALT_INVERSE_STRONG} !important`,
        padding: '10px 12px',
        '.uiOutputRichText': {
          ...getBodySmallNormalStyles(theme),
          fontFamily: theme.typography.fontFamily
        }
      },
      '.embeddedServiceLiveAgentStateChatInputFooter': {
        '.chatActionButton.Send': {
          display: 'none'
        },
        '.footerMenuWrapper': {
          marginRight: '15px',
          '.footer-menu': {
            width: '20px',
            '.slds-dropdown-trigger': {
              padding: '0px !important',
              '.slds-dropdown_left': {
                left: '-5px !important',
                borderRadius: '5px',
                boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
                marginBottom: '25px',
                maxWidth: '255px',
                height: '72px',
                '::after': {
                  content: '""',
                  position: 'absolute',
                  left: '8px',
                  right: '0',
                  width: '0',
                  height: '0',
                  bottom: '-10px',
                  borderTop: ' 10px solid #ffffff',
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent'
                }
              },
              '.slds-button__icon': {
                height: '20px',
                width: '20px'
              },
              '.footer-menu-items': {
                width: '255px',
                height: '72px',
                borderRadius: '5px',
                border: 'none',
                '.slds-dropdown__item': {
                  border: 'none',
                  a: {
                    ...getBody2MediumStyles(theme),
                    justifyContent: 'center',
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: COLOUR_BUTTON_SECONDARY_BG,
                    border: `1px solid ${COLOUR_BUTTON_SECONDARY_BORDER}`,
                    color: COLOUR_BUTTON_SECONDARY_TEXT,
                    width: '223px',
                    height: '40px',
                    borderRadius: '100px',
                    paddingLeft: '0px',
                    top: '16px',
                    margin: '0 auto',
                    ':focus': {
                      border: 'none',
                      boxShadow: 'none',
                      textDecoration: 'none'
                    }
                  }
                }
              }
            }
          }
        },
        '&.dynamicResizeTextOneRow': {
          height: '50px !important',
          minHeight: '50px !important'
        },
        '&.dynamicResizeTextMoreThanThreeRows': {
          height: '50px !important',
          minHeight: '50px !important'
        },
        '.chasitorControls': {
          margin: '0px !important',
          height: '100%',
          '.uiInput': {
            border: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`
          }
        },
        '.chasitorText': {
          ...getBodySmallNormalStyles(theme),
          fontFamily: theme.typography.fontFamily,
          lineHeight: '22px',
          padding: '14px 15px 14px 15px',
          '::-webkit-input-placeholder': {
            color: COLOUR_TEXT_TEXT_WEAKEST
          }
        },
        '.slds-dropdown-trigger_click.slds-is-open': {
          '.slds-button': {
            backgroundImage:
              'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAAXNSR0IArs4c6QAAAXhJREFUaEPtljFKA1EQhmcO8eYdxAPYWqUQwUJIY5sqpLAQRLCwkFS2NopFQCxSWeYCHuTNO8TIhkWC7CZOM8xuJmXYYeb/vs1PEEb2wZHlgQjk3ehxGCKiJQB8M/N7lxEiugKAE2aeezPWaSjnfCsi9yJyWWv92D06pXSBiCtEvCulPAwiUHMkES0A4BERz0sp6+a7nPNERD4B4IaZn7yFae7Z+xtKKc0QcSkik+3DiGsRmddanz2GORiotXItItsAiDgrpbx4DfOvQG0BvLYhpn1F4SXkoVfutwCag/uKwkuYvYa6CqCrKDyF6Q2UUjrrK4Ddoqi1fg0iEBG9IeKmrwByzk1RnDLzdBCBvB2puec4/stpiHh7Ngx5M/L3njAUhowJxCtnDFy9LgypkRkPhCFj4Op1YUiNzHggDBkDV68LQ2pkxgNhyBi4el0YUiMzHghDxsDV68KQGpnxQBgyBq5eF4bUyIwHwpAxcPW6MKRGZjwwOkM/Vgp0NdoKaxcAAAAASUVORK5CYII=)',
            height: '20px',
            width: '20px',
            position: 'relative',
            top: '10px',
            '.slds-button__icon': {
              display: 'none'
            }
          }
        },
        '.slds-button': {
          color: COLOUR_TEXT_BLACK,
          ':hover, :active': {
            color: COLOUR_TEXT_BLACK
          }
        },
        '&.chasitorInputWrapper': {
          background: `${COLOUR_BG_BACKGROUND} !important`,
          padding: '10px 10px 10px 15px',
          width: 'auto'
        }
      },

      '.avatar.iconAvatar': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT,
        border: `1px solid ${COLOUR_BORDER_BORDER_SELECTION_HOVER}`,
        height: '36px',
        width: '36px',
        '&.agentInitial': {
          ...getBody1BoldStyles(theme),
          color: COLOUR_TEXT_TEXT_INPUT_LABEL,
          fontFamily: theme.typography.fontFamily,
          lineHeight: '25px',
          top: '5px',
          margin: '0 9px 0 0'
        }
      },
      '.waitingGreetingContent': {
        margin: '0px !important',
        '.waitingGreeting': {
          ...getBody1MediumStyles(theme),
          fontFamily: theme.typography.fontFamily,
          padding: '0px !important',
          marginBottom: '15px'
        },
        '.waitingMessage': {
          ...getBody2NormalStyles(theme),
          fontFamily: theme.typography.fontFamily,
          color: COLOUR_TEXT_TEXT_WEAK,
          padding: '0px !important',
          marginBottom: '40px'
        }
      },
      '.dialogTextContainer': {
        padding: '40px',
        '#dialogTextTitle': {
          ...getBody2MediumStyles(theme),
          fontFamily: theme.typography.fontFamily,
          textAlign: 'left',
          marginBottom: '15px'
        },
        '#dialogTextBody': {
          ...getBody2NormalStyles(theme),
          fontFamily: theme.typography.fontFamily,
          color: COLOUR_TEXT_TEXT_WEAK,
          padding: '0px',
          margin: '0px',
          '.uiOutputRichText': {
            textAlign: 'left',
            p: {
              margin: '0px'
            }
          }
        }
      },
      // live chat dialog buttons styling
      '.waitingStateButtonContainer': {
        padding: '20px 24px 23px 24px',
        '.waitingCancelChat.uiButton.embeddedServiceSidebarButton': {
          height: '45px',
          width: '100%',
          padding: '8px 20px',
          background: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
          border: `1px solid ${COLOUR_BUTTON_OUTLINE_BORDER} !important`,
          borderRadius: '100px',
          textDecoration: 'none',
          ...getBody2MediumStyles(theme),
          fontFamily: theme.typography.fontFamily,
          'not(:disabled):hover': {
            background: `${COLOUR_BUTTON_OUTLINE_BG} !important`
          },
          '.label.bBody': {
            backgroundColor: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
            color: `${COLOUR_BUTTON_OUTLINE_TEXT} !important`,
            fontFamily: theme.typography.fontFamily
          },
          ':focus': {
            boxShadow: 'none !important'
          }
        }
      },
      '.dialogButtonContainer': {
        padding: '20px 24px 24px 24px !important',
        button: {
          borderRadius: '100px',
          textDecoration: 'none',
          padding: '8px 20px',
          margin: '0px',
          height: '45px!important',
          width: '100%',
          ...getBody2MediumStyles(theme),
          fontFamily: theme.typography.fontFamily,
          ':focus': {
            boxShadow: 'none !important'
          },
          '&.dialog-button-0': {
            // Color button
            background: `${COLOUR_BUTTON_PRIMARY_BG} !important`,
            border: `1px solid ${COLOUR_BUTTON_PRIMARY_BORDER} !important`,
            marginBottom: '20px',
            'not(:disabled):hover': {
              background: `${COLOUR_BUTTON_PRIMARY_BG} !important`
            },
            '.label.bBody': {
              background: `${COLOUR_BUTTON_PRIMARY_BG} !important`,
              color: `${COLOUR_BUTTON_PRIMARY_TEXT} !important`
            }
          },
          '&.dialog-button-1': {
            //whiteoutline button
            background: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
            border: `1px solid ${COLOUR_BUTTON_OUTLINE_BORDER} !important`,
            'not(:disabled):hover': {
              background: `${COLOUR_BUTTON_OUTLINE_BG} !important`
            },
            '.label.bBody': {
              backgroundColor: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
              color: `${COLOUR_BUTTON_OUTLINE_TEXT} !important`
            }
          }
        }
      },
      '.endChatContainer': {
        padding: '20px 24px',
        button: {
          borderRadius: '100px',
          textDecoration: 'none',
          padding: '8px 20px',
          width: '100% !important',
          height: '45px !important',
          margin: '0px',
          ...getBody2MediumStyles(theme),
          fontFamily: theme.typography.fontFamily,
          ':focus': {
            boxShadow: 'none !important'
          },

          '&.closeChatButton': {
            marginBottom: '20px',
            background: `${COLOUR_BUTTON_PRIMARY_BG} !important`,
            border: `1px solid ${COLOUR_BUTTON_PRIMARY_BORDER} !important`,
            'not(:disabled):hover': {
              background: `${COLOUR_BUTTON_PRIMARY_BG} !important`
            },
            '.label.bBody': {
              background: `${COLOUR_BUTTON_PRIMARY_BG} !important`,
              color: `${COLOUR_BUTTON_PRIMARY_TEXT} !important`
            }
          },
          '&.saveTranscriptButton': {
            //whiteoutline button
            background: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
            border: `1px solid ${COLOUR_BUTTON_OUTLINE_BORDER} !important`,
            'not(:disabled):hover': {
              background: `${COLOUR_BUTTON_OUTLINE_BG} !important`
            },
            '.label.bBody': {
              backgroundColor: `${COLOUR_BUTTON_OUTLINE_BG} !important`,
              color: `${COLOUR_BUTTON_OUTLINE_TEXT} !important`
            }
          }
        }
      }
    },
    '.dockableContainer.showDockableContainer': {
      '.sidebarHeader[embeddedService-chatHeader_chatHeader]': {
        minHeight: '60px'
      }
    }
  };
});

const ContentHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLivechatEnabled'
})<HeaderProps>(({ theme, isLivechatEnabled }) => {
  const {
    colours: {
      backgrounds: { background, backgroundAltInverse }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND: HexColour = background;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour = backgroundAltInverse;

  return {
    backgroundColor: isLivechatEnabled ? COLOUR_BG_BACKGROUND : COLOUR_BG_BACKGROUND_ALT_INVERSE,
    borderRadius: '8px 8px 0px 0px',
    padding: isLivechatEnabled ? '32px' : '24px 32px'
  };
});

const HeaderTitle = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'isLivechatEnabled'
})<HeaderProps>(({ theme, isLivechatEnabled }) => {
  const {
    colours: {
      text: { text, textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: string = text;
  const COLOUR_TEXT_TEXT_INVERSE: string = textInverse;

  return {
    color: isLivechatEnabled ? COLOUR_TEXT_TEXT : COLOUR_TEXT_TEXT_INVERSE,
    marginBottom: '4px'
  };
});

const HeaderDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: string = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '24px'
  };
});

const ContentBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLivechatEnabled'
})<HeaderProps>(({ theme, isLivechatEnabled }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: string = backgroundAlt;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    padding: '32px 24px',
    borderRadius: '0px 0px 8px 8px',
    '.help-menu': {
      ...(isLivechatEnabled
        ? {
            'li:first-child': {
              marginBottom: '16px'
            },
            'li:nth-child(2)': {
              marginBottom: '5px'
            }
          }
        : {
            li: {
              marginBottom: '16px'
            },
            'li:last-child': {
              marginBottom: '5px'
            }
          })
    },
    '.contact-details': {
      marginLeft: '26px',
      'li:first-child': {
        marginBottom: '16px'
      },
      li: {
        marginBottom: '5px'
      },
      'li:last-child': {
        marginBottom: '0px'
      }
    }
  };
});

const TimingText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeakest }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAKEST: string = textWeakest;

  return {
    color: COLOUR_TEXT_TEXT_WEAKEST
  };
});

const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(() => {
  return {
    minWidth: '16px',
    marginRight: '10px'
  };
});

const StyledList = styled(List)(() => {
  return {
    paddingTop: '0px',
    paddingBottom: '0px'
  };
});

const StyledListItem = styled(ListItem)(() => {
  return {
    paddingTop: '0px',
    paddingBottom: '0px'
  };
});
const StyledListItemText = styled(ListItemText)(() => {
  return {
    marginTop: '0px',
    marginBottom: '0px'
  };
});

const RPHelpSection: FC<RPHelpSectionProps> = (props: RPHelpSectionProps) => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { pathname } = useLocation();

  const { loading, firstName } = useSelector(getRegistrationDetails);

  const { registerDeviceLoading, generateTokenLoading } = useSelector(getAuthorisationDetails);

  const headingText: string = translate('helpSection.heading');
  const subheadingText: string = translate('helpSection.subHeading');
  const emailText: string = translate('helpSection.emailTxt');
  const callText: string = translate('helpSection.callTxt');
  const faqText: string = translate('helpSection.faqTxt');
  const officeTxt: string = translate('helpSection.officeTxt');
  const reqCallTxt: string = translate('helpSection.reqCallTxt');
  const phoneNoText: string = translate('helpSection.phoneNoTxt');
  const time1Text: string = translate('helpSection.time1Txt');
  const time2Text: string = translate('helpSection.time2Txt');
  const time3Text: string = translate('helpSection.time3Txt');
  const chatOnlineBtnText: string = translate('helpSection.onlineChatBtnTxt');
  const chatOfflineBtnText: string = translate('helpSection.offlineChatBtnTxt');
  const emailTo: string = translate('helpSection.emailTo');
  const telephoneTo: string = translate('helpSection.telephoneTo');
  const faqTo: string = translate('helpSection.faqTo');
  const locationTo: string = translate('helpSection.locationTo');
  const reqCallTo: string = translate('helpSection.reqCallTo');

  const [showHelpSection, setShowHelpSection] = useState<boolean>(false);
  const [showChatWidget, setShowChatWidget] = useState<boolean>(false); // New state for chat widget visibility

  const HelpEmailIcon = useMemo(() => getIcon(tenant, 'helpEmail'), [tenant]);
  const HelpMobileIcon = useMemo(() => getIcon(tenant, 'helpMobile'), [tenant]);
  const HelpCloseIcon = useMemo(() => getIcon(tenant, 'helpClose'), [tenant]);
  const AgentOnlineIcon = useMemo(() => getIcon(tenant, 'agentOnline'), [tenant]);
  const HelpFaqIcon = useMemo(() => getIcon(tenant, 'helpFaq'), [tenant]);
  const HelpMapIcon = useMemo(() => getIcon(tenant, 'helpMap'), [tenant]);

  const { isLivechatEnabled } = props;

  const isPageLoading: boolean =
    registerDeviceLoading === 'loading' || generateTokenLoading === 'loading' || loading === 'loading';

  const pathURLSplitArray: Array<string> = pathname.split('/');

  const routeLiveChatConfigObj: RouteLiveChatConfigType | undefined = ROUTES_LIVE_CHAT_MAPPING.find(
    (el) => pathURLSplitArray.indexOf(el.route) !== -1
  );
  const routeLiveChatConfigValue: LiveChatRouteOffsetType = routeLiveChatConfigObj
    ? routeLiveChatConfigObj.config
    : LiveChatConfig.disableLiveChat;

  const isChatAvailableOnPage: boolean =
    routeLiveChatConfigValue && routeLiveChatConfigValue !== LiveChatConfig.disableLiveChat;
  const isHelpOffSetApplicable: boolean =
    routeLiveChatConfigValue && routeLiveChatConfigValue === LiveChatConfig.withOffset;

  const shouldShowLiveChatIcon: boolean = isChatAvailableOnPage && !isPageLoading;

  const showtime2Text: boolean = tenant ? LIVE_CHAT_TIME_TEXT_AVAILABILITY_ORGANIZATIONS_LIST.includes(tenant) : false;

  const contentAreaElement: HTMLElement | null = document.getElementById('rp-split-layout-content-area');
  const helpOffset: number = contentAreaElement?.getBoundingClientRect()?.right || 0;

  const helpWithLiveChat: JSX.Element = (
    <>
      <div className="help-menu">
        <StyledList>
          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpEmailIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPLink text={emailText} href={emailTo} variant="body2" type="bold" target="_blank" underline="none" />
            </StyledListItemText>
          </StyledListItem>
          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpMobileIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPText variant="body2" type="bold" text={callText} />
            </StyledListItemText>
          </StyledListItem>
        </StyledList>
      </div>
      <div className="contact-details">
        <StyledList>
          <StyledListItem disableGutters>
            <StyledListItemText>
              <RPLink
                text={phoneNoText}
                href={telephoneTo}
                variant="body2"
                type="bold"
                target="_blank"
                underline="none"
              />
            </StyledListItemText>
          </StyledListItem>
          <StyledListItem disableGutters>
            <StyledListItemText>
              <TimingText variant="subtitle1" type="normal" text={time1Text} />
            </StyledListItemText>
          </StyledListItem>
          {showtime2Text && (
            <StyledListItem disableGutters>
              <StyledListItemText>
                <TimingText variant="subtitle1" type="normal" text={time2Text} />
              </StyledListItemText>
            </StyledListItem>
          )}
        </StyledList>
      </div>
    </>
  );

  const helpWithoutLiveChat: JSX.Element = (
    <>
      <div className="help-menu">
        <StyledList>
          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpFaqIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPLink text={faqText} href={faqTo} variant="body2" type="bold" target="_blank" underline="none" />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpMobileIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPLink text={reqCallTxt} href={reqCallTo} variant="body2" type="bold" target="_blank" underline="none" />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpEmailIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPLink text={emailText} href={emailTo} variant="body2" type="bold" target="_blank" underline="none" />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpMapIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPLink text={officeTxt} href={locationTo} variant="body2" type="bold" target="_blank" underline="none" />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemIcon>
              <HelpMobileIcon />
            </StyledListItemIcon>
            <StyledListItemText>
              <RPText variant="body2" type="bold" text={callText} />
            </StyledListItemText>
          </StyledListItem>
        </StyledList>
      </div>
      <div className="contact-details">
        <StyledList>
          <StyledListItem disableGutters>
            <StyledListItemText>
              <RPLink
                text={phoneNoText}
                href={telephoneTo}
                variant="body2"
                type="bold"
                target="_blank"
                underline="none"
              />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemText>
              <TimingText variant="subtitle1" type="normal" text={time1Text} />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemText>
              <TimingText variant="subtitle1" type="normal" text={time2Text} />
            </StyledListItemText>
          </StyledListItem>

          <StyledListItem disableGutters>
            <StyledListItemText>
              <TimingText variant="subtitle1" type="normal" text={time3Text} />
            </StyledListItemText>
          </StyledListItem>
        </StyledList>
      </div>
    </>
  );

  const liveChatConfig: liveChatConfigType = getLiveChatConfig(tenant);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (showHelpSection && !showChatWidget) {
      setShowHelpSection(false);
    }
  };

  const toggleHelpButton = () => {
    setShowHelpSection(!showHelpSection);
  };

  useEffect(() => {
    let isScriptLoaded = false;
    const loadScript = () => {
      if (!isScriptLoaded) {
        const existingScript = document.getElementById('eswScript');

        const chatOnlineBtnText: string = localStorage.getItem('chatNow') || 'Chat Now';
        const chatOfflineBtnText: string = localStorage.getItem('chatOffline') || 'Agent Offline';

        console.log('RPHelpSection initESW chatOnlineBtnText: ', chatOnlineBtnText);
        console.log('RPHelpSection initESW chatOfflineBtnText: ', chatOfflineBtnText);
        console.log('RPHelpSection initESW existingScript: ', existingScript);

        if (isLivechatEnabled) {
          if (!existingScript) {
            var s = document.createElement('script');
            s.setAttribute('src', liveChatConfig?.eswURL);
            s.id = 'eswScript';

            s.onload = () => {
              initESW(
                chatOnlineBtnText,
                chatOfflineBtnText,
                firstName,
                setShowHelpSection,
                liveChatConfig,
                setShowChatWidget
              );
            };
            document.body.appendChild(s);
          } else {
            initESW(
              chatOnlineBtnText,
              chatOfflineBtnText,
              firstName,
              setShowHelpSection,
              liveChatConfig,
              setShowChatWidget
            );
            isScriptLoaded = true;
          }
        }
      }
    };

    loadScript();
  }, [firstName, isLivechatEnabled, liveChatConfig]);

  return (
    <StyledRPHelpSection
      shouldShowLiveChatIcon={shouldShowLiveChatIcon}
      layout={layout}
      helpOffset={helpOffset}
      isHelpOffSetApplicable={isHelpOffSetApplicable}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <ChatButtonWrapper
            layout={layout}
            onClick={toggleHelpButton}
            name="chatIconButton"
            data-testid="rp-button-chat-icon"
            helpOffset={helpOffset}
            isHelpOffSetApplicable={isHelpOffSetApplicable}
          >
            {showHelpSection ? <HelpCloseIcon /> : <AgentOnlineIcon />}
          </ChatButtonWrapper>

          <ContentSection
            layout={layout}
            showHelpSection={showHelpSection}
            id="help-content"
            helpOffset={helpOffset}
            isHelpOffSetApplicable={isHelpOffSetApplicable}
          >
            <ContentHeader id="help-header" isLivechatEnabled={isLivechatEnabled}>
              <HeaderTitle variant="body1" type="bold" text={headingText} isLivechatEnabled={isLivechatEnabled} />
              {isLivechatEnabled && <HeaderDescription variant="body2" type="normal" text={subheadingText} />}
            </ContentHeader>
            <ContentBody id="help-body" isLivechatEnabled={isLivechatEnabled}>
              {isLivechatEnabled ? helpWithLiveChat : helpWithoutLiveChat}
            </ContentBody>
          </ContentSection>
        </div>
      </ClickAwayListener>
    </StyledRPHelpSection>
  );
};

export default memo(RPHelpSection);
