import { featureFlagsType } from '../types/FeatureFlagsTypes';

export const featureFlags: featureFlagsType = {
  onfido: {
    facialSimilarityMotion: false,
    facialSimilarityVideo: true,
    allowLegalEntity: true,
    updateUploadDocLaterLink: false
  },
  ppcData: {
    allowPPCCookies: true
  },
  enterDetails: {
    showEmploymentFields: true
  },
  landingPage: {
    useReactLandingPage: true,
    isCFSBEnabled: false
  },
  kycFail: {
    updateUploadDocLaterLink: false
  }
};