import { useContext, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

// Contexts
import { LanguageContext, TenantContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import { SplitLayout } from '../../organisms/SplitLayout';
import FullLayout from '../../organisms/FullLayout';
// Hooks
import { useAnimation, useScrollToContentTop } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { RegistrationReturnInactiveProps, RegistrationReturnProps } from '../../../core/types/RegistrationReturnTypes';
// RTK Slice
import { updateCurrentStep } from '../../../redux/modules/registrationStepsSlice';
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Constants
import {
  DOC_UPLOAD_COMPLETE_PAGE_URL,
  DOC_UPLOAD_COMPLETE_RETURN_INACTIVE_PAGE_URL,
  DOC_UPLOAD_COMPLETE_RETURN_PAGE_URL,
  INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  KYC_PASS_PAGE_URL,
  ORGANIZATION_CODE_MAPPING
} from '../../../core/utils/Constants/Constants';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Feature Flag
import { featureFlags } from '../../../core/FeatureFlags/FeatureFlags';

const DocumentUploadedContainer = styled(Box)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '30px',
    color: COLOUR_TEXT_TEXT_WEAK,
    padding: '0 40px',
    boxSizing: 'border-box',

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_WEAK,
      textAlign: 'center'
    }
  };
});

interface TextWrapperProps extends LayoutProps {}

const TextWrapper = styled(Box)<TextWrapperProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isDesktopLayout ? '20px' : isTabletLayout ? '15px' : '10px'
  };
});

interface DocumentUploadedProps extends RegistrationReturnProps, RegistrationReturnInactiveProps {}

const {
  kycFail: { updateUploadDocLaterLink }
} = featureFlags;

const DocumentUploaded = ({
  isRegistrationReturn = false,
  isRegistrationReturnInactive = false
}: DocumentUploadedProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  let dataFetchedRef = useRef(false);

  const DocUploadedSVG = useMemo(() => getIcon(tenant, 'documentUploaded'), [tenant]);

  const [animateContent, setAnimateContent] = useAnimation(false, 500);
  const cssTransitionRef = useRef(null);
  const { isPoaRequired, isPoiRequired } = useSelector(getRegistrationDetails);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const headerVariant: 'h2' | 'h3' = isMobileLayout ? 'h3' : 'h2';
  const bodyVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';
  const {
    eventAction: { load },
    kycFailedDocument: {
      load: {
        poiDocumentUploadComplete,
        poaDocumentUploadComplete,
        poaDocumentUploadCompleteReturnInactive,
        poaPoiDocumentUploadComplete,
        poaPoiDocumentUploadCompleteReturnInactive
      }
    }
  } = GoogleAnalyticsTagsMapping;

  const headingText: string = translate('documentUploaded.heading');
  const subHeadingLine1Text: string = translate('documentUploaded.subHeading');

  const shouldUpdateUploadDocLaterLink: boolean =
    updateUploadDocLaterLink &&
    INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(
      tenant || ORGANIZATION_CODE_MAPPING.currenciesdirect
    );

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    TriggerGoogleAnalyticsTag(
      load,
      isPoaRequired && isPoiRequired
        ? isRegistrationReturnInactive
          ? poaPoiDocumentUploadCompleteReturnInactive
          : poaPoiDocumentUploadComplete
        : isPoiRequired
        ? poiDocumentUploadComplete
        : isRegistrationReturnInactive
        ? poaDocumentUploadCompleteReturnInactive
        : poaDocumentUploadComplete
    );

    dispatch(
      updateCurrentStep({
        stepType: 'confirming-you'
      })
    );
    setTimeout(() => {
      const navigateToURL: string = isRegistrationReturn
        ? DOC_UPLOAD_COMPLETE_RETURN_PAGE_URL
        : isRegistrationReturnInactive
        ? DOC_UPLOAD_COMPLETE_RETURN_INACTIVE_PAGE_URL
        : shouldUpdateUploadDocLaterLink
        ? KYC_PASS_PAGE_URL
        : DOC_UPLOAD_COMPLETE_PAGE_URL;
      navigate(navigateToURL, { state: { fromOnFidoPage: false } });
    }, 5000);
  });

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const children: JSX.Element = (
    <CSSTransition
      nodeRef={cssTransitionRef}
      in={animateContent}
      timeout={500}
      classNames="slideUp"
      unmountOnExit
      onEnter={() => setAnimateContent}
      onExited={() => setAnimateContent}
    >
      <DocumentUploadedContainer ref={cssTransitionRef}>
        <DocUploadedSVG />
        <TextWrapper layout={layout}>
          <RPText variant={headerVariant} type="bold" text={headingText} />
          <RPText variant={bodyVariant} type="normal" text={subHeadingLine1Text} />
        </TextWrapper>
      </DocumentUploadedContainer>
    </CSSTransition>
  );

  return isRegistrationReturnInactive ? (
    <FullLayout
      layoutType="old"
      hideAdditionalInteractions
      stickHeaderAtTop={false}
      shouldAddWrapperContainerStyling={true}
    >
      {children}
    </FullLayout>
  ) : (
    <SplitLayout isAnimating={false} layoutVariant={'partial'} hideAdditionalInteractions>
      {children}
    </SplitLayout>
  );
};

export default DocumentUploaded;
