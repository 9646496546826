import { FC, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps, IconButton } from '@mui/material';

// Contexts
import { LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPLogoWrapper from '../../organisms/RPLogoWrapper';
// Types
import { IconObject } from '../../../core/types/IconOrgTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutSize } from '../../../core/types/LayoutTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getLandingPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';

export type HeaderType = 'normal' | 'old';

interface FullLayoutHeaderProps {
  headerType: HeaderType;
  stickHeaderAtTop?: boolean;
  isOnlyLayoutView: boolean;
  showCloseIcon: boolean;
  currentLogo: keyof IconObject;
  closeIconClickHandler: () => void;
}

interface LogoContainerProps extends LayoutProps {
  isFullLayoutHeaderOld: boolean;
}

interface CloseIconContainerProps extends LayoutProps {
  isFullLayoutHeaderOld: boolean;
}

interface StyledIconButtonProps {
  isFullLayoutHeaderOld: boolean;
}

interface HeaderProps extends BoxProps {
  stickHeaderAtTop: boolean;
  size: LayoutSize;
  isOnlyLayoutView: boolean;
  isFullLayoutHeaderOld: boolean;
}

const Header = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isOnlyLayoutView' && prop !== 'isFullLayoutHeaderOld' && prop !== 'stickHeaderAtTop'
})<HeaderProps>(({ theme, size, isOnlyLayoutView, isFullLayoutHeaderOld, stickHeaderAtTop }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isMobileLayout: boolean = isMobileLayoutApplicable(size);

  return {
    ...(isOnlyLayoutView && { border: '1px solid black' }),
    backgroundColor: isFullLayoutHeaderOld ? COLOUR_BG_BACKGROUND_ALT : 'inherit',
    ...(stickHeaderAtTop && {
      position: 'absolute',
      top: 0,
      right: 0
    }),
    zIndex: 1,
    width: '100%',
    height: isFullLayoutHeaderOld ? '82px' : isMobileLayout ? '72px' : '130px',
    display: 'flex',
    justifyContent: 'space-between',
    ...(isFullLayoutHeaderOld && {
      alignItems: 'center'
    })
  };
});

const LogoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFullLayoutHeaderOld'
})<LogoContainerProps>(({ layout, isFullLayoutHeaderOld }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    width: isMobileLayout || isFullLayoutHeaderOld ? '130px' : '180px',
    height: isMobileLayout || isFullLayoutHeaderOld ? '36px' : '50px',
    color: 'white',
    ...(isFullLayoutHeaderOld
      ? {
          '>button': {
            padding: '0px',
            '>svg': {
              width: '130px',
              height: '36px'
            }
          },
          marginLeft: isMobileLayout ? '24px' : '120px'
        }
      : {
          margin: isMobileLayout ? '18px 0 0 25px' : 40
        })
  };
});

const CloseIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFullLayoutHeaderOld'
})<CloseIconContainerProps>(({ layout, isFullLayoutHeaderOld }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    alignSelf: 'center',
    marginRight: isFullLayoutHeaderOld ? (isMobileLayout ? '24px' : '120px') : isMobileLayout ? '25px' : '40px'
  };
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isFullLayoutHeaderOld'
})<StyledIconButtonProps>(({ theme, isFullLayoutHeaderOld }) => {
  const {
    colours: {
      text: { textWeak, textInverse, textInputFocus, textInputIcon }
    }
  } = theme;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg': {
      width: '40px',
      height: '40px',

      '> path': {
        fill: isFullLayoutHeaderOld ? COLOUR_TEXT_TEXT_WEAK : COLOUR_TEXT_TEXT_INVERSE
      }
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    }
  };
});

const FullLayoutHeader: FC<FullLayoutHeaderProps> = (props: FullLayoutHeaderProps) => {
  const { layout: layoutSize } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const landingPageURL: string = getLandingPageURL(tenant);

  const {
    headerType = 'normal',
    stickHeaderAtTop = true,
    isOnlyLayoutView,
    showCloseIcon,
    currentLogo,
    closeIconClickHandler
  } = props;

  const isFullLayoutHeaderOld: boolean = headerType === 'old';

  return (
    <Header
      stickHeaderAtTop={stickHeaderAtTop}
      size={layoutSize}
      isOnlyLayoutView={isOnlyLayoutView}
      isFullLayoutHeaderOld={isFullLayoutHeaderOld}
    >
      <LogoContainer layout={layoutSize} isFullLayoutHeaderOld={isFullLayoutHeaderOld}>
        <RPLogoWrapper
          logoVariant={currentLogo}
          tenant={tenant}
          link={landingPageURL}
          disableClick={isFullLayoutHeaderOld}
        />
      </LogoContainer>
      {showCloseIcon && (
        <CloseIconContainer layout={layoutSize} isFullLayoutHeaderOld={isFullLayoutHeaderOld}>
          <StyledIconButton
            disableRipple
            onClick={closeIconClickHandler}
            name="closeButton"
            data-testid="rp-button-close"
            isFullLayoutHeaderOld={isFullLayoutHeaderOld}
          >
            <CloseIcon />
          </StyledIconButton>
        </CloseIconContainer>
      )}
    </Header>
  );
};

export default FullLayoutHeader;
