import { useLocation } from 'react-router-dom';

const useCurrentStepFromLocation = (): string => {
  const { pathname } = useLocation();

  let matchedIndex: number = -1;

  const splitPathnameArray: Array<string> = pathname.split('/');

  splitPathnameArray.forEach((pathSplit, index) => {
    if(pathSplit.includes('registration')) {
      matchedIndex = index;
    }
  });

  return splitPathnameArray[matchedIndex + 1].charAt(0);
};

export default useCurrentStepFromLocation;