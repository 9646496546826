import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// import { CreateAsyncThunkCustom } from '../../core/utils/CreateAsyncThunkCustom/CreateAsyncThunkCustom';

type EmailType = {
  email: string;
};

/*const post = async (thunkAPI: any, payload: any) => {
  try {
    const response = await axios({
      url: '/registration-service/checkDuplicateAccount',
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "accept": "*!/!*"
      },
      data: payload
    })

    return response && response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.response.data.message });
  }
}*/

const { orgCode, parentOrganizationCode, orgCodeID } = GetBasicDetails();

const checkDuplicateAccount = createAsyncThunk(
  "registration-service/checkDuplicateAccount",
  async (data: EmailType, thunkAPI) => {
    const payload = {
      "emailID": data.email,
      "orgCode": orgCode,
      "parentOrgCode": parentOrganizationCode,
      "orgID": orgCodeID
    }

    try {
      const response = await axiosWrapper('checkDuplicateAccount', 'POST', thunkAPI, payload);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

/*const checkDuplicateAccount1 = (data: EmailType) => CreateAsyncThunkCustom(
  "registration-service/checkDuplicateAccount",
  "POST",
  '/registration-service/checkDuplicateAccount',
  {
    "emailID": data.email,
    "orgCode": "Currencies Direct",
    "parentOrgCode": "Currencies Direct",
    "orgID": 2
  }
);*/

export default checkDuplicateAccount;