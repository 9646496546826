import React, { useContext, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useWindowSize } from 'usehooks-ts';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import RPLogoWrapper from '../../organisms/RPLogoWrapper';
import { ListWithIcon } from '../../molecules/ListWithIcon';
import { SocialProof } from '../../molecules/SocialProof';
import RPFooter from '../RPFooter';
import { RPStepProgressTracker } from '../../molecules/RPStepProgressTracker';
// Hooks
import { useAnimation } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { IconObject } from '../../../core/types/IconOrgTypes';
import { RegistrationStep, StepTypes } from '../../../core/types/StepProgressTrackerTypes';
// RTK Slice
import { getRegistrationSteps, updateCurrentStep } from '../../../redux/modules/registrationStepsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import GetRegistrationStepsData from '../../../core/utils/GetRegistrationStepsData';
import { getLandingPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getImage } from '../../../core/utils/ImageOrgData';
// Constants
import {
  EN_US,
  LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  ORGANIZATION_CODE_MAPPING,
  STEPS_ROUTE_MAPPING
} from '../../../core/utils/Constants/Constants';

interface SideBarHeadingProps {
  layoutSize: LayoutSize;
}

interface StyledImageContainerProps {
  layoutSize: LayoutSize;
}

interface SideBarContentWrapperProps {
  layoutSize: LayoutSize;
  isFullLayout: boolean;
  isFromLandingOrRegister: boolean;
}

const SideBarHeading = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutSize'
})<SideBarHeadingProps>(({ theme, layoutSize }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  const isTabletLayout: boolean = layoutSize === 'tablet';

  return {
    width: '100%',

    '& .MuiTypography-root.MuiTypography-h1': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      maxWidth: isTabletLayout ? '400px' : '580px',
      paddingBottom: isTabletLayout ? '40px' : '50px'
    }
  };
});

const SideBarContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layoutSize' && prop !== 'isFullLayout' && prop !== 'isFromLandingOrRegister'
})<SideBarContentWrapperProps>(({ layoutSize, isFullLayout, isFromLandingOrRegister }) => {
  const isDesktopLayout: boolean = layoutSize === 'desktop';
  const isMobileLayout: boolean = layoutSize === 'mobile';

  return {
    display: 'flex',
    flexDirection: 'column',
    ...(isFullLayout
      ? isFromLandingOrRegister
        ? {
            gap: isDesktopLayout ? '80px' : '35px',
            marginBottom: isDesktopLayout ? '20px' : '30px'
          }
        : {
            gap: isDesktopLayout ? '140px' : '60px'
          }
      : {
          gap: isDesktopLayout ? '100px' : '70px'
        })
  };
});

interface StyledSideBarChildWrapperProps {
  layoutSize: LayoutSize;
  isFromLandingOrRegister: boolean;
}

const StyledSideBarChildWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layoutSize' && prop !== 'isFromLandingOrRegister'
})<StyledSideBarChildWrapperProps>(({ layoutSize, isFromLandingOrRegister }) => {
  const isTabletLayout: boolean = layoutSize === 'tablet';

  return {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...(isFromLandingOrRegister && {
      gap: '50px',
      width: '100%',
      maxWidth: isTabletLayout ? '597px' : '735px',
      maxHeight: '944px',
      margin: '0 0 0 auto'
    })
  };
});

interface LogoWrapperProps {
  isFromLandingOrRegister: boolean;
}

const LogoWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<LogoWrapperProps>(({ isFromLandingOrRegister }) => {
  return {
    display: 'inline-flex',
    ...(isFromLandingOrRegister && {
      '& > button': {
        cursor: 'auto'
      }
    })
  };
});

const StyledImageContainer = styled('img', {
  shouldForwardProp: (prop) => prop !== 'layoutSize'
})<StyledImageContainerProps>(({ layoutSize }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layoutSize);

  return {
    width: isMobileLayout ? '235px' : '100%',
    maxWidth: '540px'
  }
});

const StepProgress = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    color: COLOUR_TEXT_TEXT_INVERSE
  };
});

interface SideBarProps extends BoxProps {
  size: LayoutSize;
  variant: 'full' | 'partial';
  sideBarRef?: React.Ref<unknown>;
  isFromLandingOrRegister: boolean;
}

const StyledSideBar = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' && prop !== 'variant' && prop !== 'sideBarRef' && prop !== 'isFromLandingOrRegister'
})<SideBarProps>(({ theme, size, variant, isFromLandingOrRegister }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverse, backgroundLandingPage }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour = backgroundAltInverse;
  const COLOUR_BG_BACKGROUND_LANDING_PAGE: HexColour = backgroundLandingPage;
  const isTabletLayout: boolean = size === 'tablet';
  const currentMaxWidth: string =
    variant === 'full'
      ? isTabletLayout
        ? isFromLandingOrRegister
          ? '50%'
          : '510px'
        : isFromLandingOrRegister
        ? '51%'
        : '735px'
      : isTabletLayout
      ? '400px'
      : '500px';
  const currentPadding: string = isFromLandingOrRegister || isTabletLayout ? '40px' : '60px';

  return {
    ...(isFromLandingOrRegister
      ? {
          backgroundColor: COLOUR_BG_BACKGROUND_LANDING_PAGE,
          height: 'inherit'
        }
      : {
          backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE,
          height: '100%'
        }),
    width: '50%',
    maxWidth: currentMaxWidth,
    transform: 'none',
    padding: currentPadding,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    transition: 'all 0.3s'
  };
});

interface FooterProps {
  layoutSize: LayoutSize;
}

const Footer = styled('footer', {
  shouldForwardProp: (prop) => prop !== 'layoutSize'
})<FooterProps>(({ layoutSize }) => {
  const isDesktopLayout: boolean = layoutSize === 'desktop';

  return {
    marginTop: isDesktopLayout ? '20px' : '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center'
  };
});

const SideBar = (props: SideBarProps) => {
  const dispatch: any = useDispatch();

  const { tenant } = useContext(TenantContext);
  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const navigate = useNavigate();

  const { height: screenHeight, width: screenWidth } = useWindowSize();

  const { steps: stepsData } = useSelector(getRegistrationSteps);

  const steps: RegistrationStep[] | [] = useMemo(
    () => GetRegistrationStepsData(stepsData, translate),
    [stepsData, translate]
  );

  const { variant: layoutVariant, size: layoutSize, sideBarRef, isFromLandingOrRegister } = props;

  const LandingPageIcon = useMemo(() => getImage(tenant, 'landingPage'), [tenant]);
  const LandingPageUSAIcon = useMemo(() => getImage(tenant, 'landingPageUSA'), [tenant]);

  const LandingPageImg: JSX.Element = (<StyledImageContainer src={LandingPageIcon} alt="Landing page image" layoutSize={layoutSize} />);
  const LandingPageUSAImg: JSX.Element = (<StyledImageContainer src={LandingPageUSAIcon} alt="Landing page USA image" layoutSize={layoutSize} />);

  const [topAnimation, setTopAnimation] = useAnimation(false);
  const [midAnimation, setMidAnimation] = useAnimation(false, 400);
  const [btmAnimation, setBtmAnimation] = useAnimation(false, 200);

  const cssTransitionLogoRef = useRef(null);
  const cssTransitionTextRef = useRef(null);
  const CSSTimeout: number = 300;

  const isFullLayout: boolean = layoutVariant === 'full';
  const isTabletLayout: boolean = layoutSize === 'tablet';

  const landingPageURL: string = getLandingPageURL(tenant);

  const isCurrentLangUS: boolean = language === EN_US;
  const isCurrentTenantCD: boolean = tenant === ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const currentLogo: keyof IconObject = isFromLandingOrRegister
    ? LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(tenant || ORGANIZATION_CODE_MAPPING.currenciesdirect)
      ? 'logoLight'
      : 'logoDark'
    : 'logoLight';

  const partialDesktopSize: boolean = screenWidth > 1194 && screenWidth < 1245;
  const showSocialProof: boolean = isFromLandingOrRegister
    ? true
    : isTabletLayout
      ? screenHeight >= 650
      : partialDesktopSize
        ? screenHeight >= 882
        : screenHeight >= 800;
  const showListWithIcon: boolean = isTabletLayout
    ? screenHeight >= 530
    : partialDesktopSize
      ? screenHeight >= 770
      : screenHeight >= 688;
  const showSideBarHeadingText: boolean = isTabletLayout
    ? screenHeight >= 360
    : partialDesktopSize
      ? screenHeight >= 572
      : screenHeight >= 539;
  const showStepProgress: boolean = isTabletLayout ? screenHeight >= 500 : screenHeight >= 555;

  const SideBarHeadingText: string = isTabletLayout
    ? translate('sidebar.heading.headingUTF') // &nbsp; equivalent UTF \u00A0
    : translate('sidebar.heading.heading');

  const handleStepClick = (stepType: StepTypes) => {
    // Should be used to handle the click event.
    const currentStep = STEPS_ROUTE_MAPPING[stepType];
    dispatch(
      updateCurrentStep({
        stepType: stepType
      })
    );

    navigate(currentStep);
  };

  return (
    <StyledSideBar {...props} ref={sideBarRef}>
      <StyledSideBarChildWrapper isFromLandingOrRegister={isFromLandingOrRegister} layoutSize={layoutSize}>
        <SideBarContentWrapper
          layoutSize={layoutSize}
          isFullLayout={isFullLayout}
          isFromLandingOrRegister={isFromLandingOrRegister}
        >
          <CSSTransition
            nodeRef={cssTransitionLogoRef}
            in={topAnimation}
            timeout={CSSTimeout}
            classNames="slideLeft"
            unmountOnExit
            onEnter={() => setTopAnimation}
            onExited={() => setTopAnimation}
          >
            <LogoWrapper ref={cssTransitionLogoRef} isFromLandingOrRegister={isFromLandingOrRegister}>
              <RPLogoWrapper
                logoVariant={currentLogo}
                tenant={tenant}
                link={landingPageURL}
                shouldShowCloseConfirmationModal={true}
              />
            </LogoWrapper>
          </CSSTransition>
          {isFromLandingOrRegister ? (
            isCurrentLangUS && isCurrentTenantCD ? (
              LandingPageUSAImg
            ) : (
              LandingPageImg
            )
          ) : (
            <SwitchTransition mode="out-in">
              <CSSTransition key={layoutVariant} timeout={CSSTimeout} classNames="fade">
                <SideBarHeading layoutSize={layoutSize}>
                  {isFullLayout ? (
                    <CSSTransition
                      nodeRef={cssTransitionTextRef}
                      in={midAnimation}
                      timeout={CSSTimeout}
                      classNames="slideLeft"
                      unmountOnExit
                      onEnter={() => setMidAnimation}
                      onExited={() => setMidAnimation}
                    >
                      <div ref={cssTransitionTextRef} className="headingArea">
                        {showSideBarHeadingText && (
                          <RPText
                            variant="h1"
                            type={isTabletLayout ? 'display-large-bold' : 'display-huge-bold'}
                            text={SideBarHeadingText}
                          />
                        )}
                        {showListWithIcon && <ListWithIcon listType="features-bullets" />}
                      </div>
                    </CSSTransition>
                  ) : (
                    <CSSTransition
                      nodeRef={cssTransitionTextRef}
                      in={midAnimation}
                      timeout={CSSTimeout}
                      classNames="slideLeft"
                      unmountOnExit
                      onEnter={() => setMidAnimation}
                      onExited={() => setMidAnimation}
                    >
                      <div ref={cssTransitionTextRef}>
                        {showStepProgress && (
                          <StepProgress>
                            <RPStepProgressTracker steps={steps} handleStepClick={handleStepClick} />
                          </StepProgress>
                        )}
                      </div>
                    </CSSTransition>
                  )}
                </SideBarHeading>
              </CSSTransition>
            </SwitchTransition>
          )}
        </SideBarContentWrapper>

        <SwitchTransition mode="out-in">
          <CSSTransition key={layoutVariant} timeout={CSSTimeout} classNames="fade">
            <>
              {isFullLayout && showSocialProof && (
                <CSSTransition
                  nodeRef={cssTransitionTextRef}
                  in={btmAnimation}
                  timeout={CSSTimeout}
                  classNames="slideLeft"
                  unmountOnExit
                  onEnter={() => setBtmAnimation}
                  onExited={() => setBtmAnimation}
                >
                  <div ref={cssTransitionTextRef} className="headingArea">
                    <SocialProof
                      isFromLandingOrRegister={isFromLandingOrRegister}
                      size={isTabletLayout ? 'medium' : 'large'}
                    />
                    {isFromLandingOrRegister && (
                      <Footer layoutSize={layoutSize}>
                        <RPFooter isFromLandingOrRegister={isFromLandingOrRegister} />
                      </Footer>
                    )}
                  </div>
                </CSSTransition>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </StyledSideBarChildWrapper>
    </StyledSideBar>
  );
};

export default SideBar;
