import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// Context
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPLogoWrapper from '../../organisms/RPLogoWrapper';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getLandingPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';

interface RPMobileHeaderProps {
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
  hideAdditionalInteractions?: boolean;
}

interface VisibleProps {
  showMenu: boolean;
}

// interface HeaderContainerProps extends VisibleProps {}

interface StyledIconProps extends VisibleProps {}

const HeaderContainer = styled('div')(() => {
  return {
    height: '36px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 25px',
    alignItems: 'center'
  };
});

const StyledIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'showMenu'
})<StyledIconProps>(({ theme, showMenu }) => {
  const {
    colours: {
      text: { text, textInputFocus, textInputIcon }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;

  return {
    width: '22px',
    height: '22px',
    position: 'relative',
    cursor: 'pointer',
    padding: 0,
    transform: 'rotate(0deg)',
    transition: '.45s ease-in-out',

    ':hover': {
      backgroundColor: 'transparent'
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> span': {
        backgroundColor: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    },

    '> span': {
      display: 'block',
      position: 'absolute',
      height: '3px',
      width: '100%',
      backgroundColor: COLOUR_TEXT_TEXT,
      borderRadius: '40px',
      opacity: 1,
      left: 0,
      transform: 'rotate(0deg)',
      transition: '.45s ease-in-out',
      transformOrigin: 'left center',

      ':nth-of-type(1)': {
        top: showMenu ? 1 : 3,
        ...(showMenu && {
          left: 2.5,
          transform: 'rotate(45deg)'
        })
      },

      ':nth-of-type(2)': {
        top: showMenu ? 12 : 10,
        ...(showMenu && {
          width: 0,
          opacity: 0
        })
      },

      ':nth-of-type(3)': {
        top: showMenu ? 16.5 : 17,
        ...(showMenu && {
          left: 2.5,
          transform: 'rotate(-45deg)'
        })
      }
    }
  };
});

const RPMobileHeader: FC<RPMobileHeaderProps> = (props: RPMobileHeaderProps) => {
  const { showMenu, setShowMenu, hideAdditionalInteractions } = props;

  const { tenant } = useContext(TenantContext);

  const landingPageURL: string = getLandingPageURL(tenant);

  return (
    <HeaderContainer>
      <RPLogoWrapper logoVariant="logo" tenant={tenant} link={landingPageURL} shouldShowCloseConfirmationModal={true} />
      {!hideAdditionalInteractions && (
        <StyledIcon
          onClick={() => setShowMenu(!showMenu)}
          showMenu={showMenu}
          name="headerMenuIconButton"
          data-testid="rp-button-header-menu-icon"
        >
          <span />
          <span />
          <span />
        </StyledIcon>
      )}
    </HeaderContainer>
  );
};

export default RPMobileHeader;
