import { useContext } from 'react';
import * as yup from 'yup';

// Contexts
import { LanguageContext } from '../../../../core/TenantProvider/contexts';
// Types
import { AddressFieldKeys } from '../../../../core/types/addressLookupTypes';
import { EnterDetailsType } from '../../../../core/types/RegistrationStepForm';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { Countries, CountryFieldMapping } from '../../../../core/utils/AddressLookup/CountryFieldsMatrix';
// Constants
import { MIN_AGE, MAX_AGE, LICENSE_STATUS_COUNTRIES } from '../../../../core/utils/Constants/Constants';
// Feature Flag
import { featureFlags } from '../../../../core/FeatureFlags/FeatureFlags';
import { useSelector } from 'react-redux';
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
import { EmploymentStatusType } from '../../../../core/types/EmploymentFieldTypes';

// Referring to a workaround mentioned in https://github.com/jquense/yup/issues/1631
interface TestContextExtended {
  from: {
    value: EnterDetailsType;
  }[];
}

const {
  enterDetails: { showEmploymentFields }
} = featureFlags;

const validateAge = (day: string, month: string, year: string) => {
  const dateOfBirth = new Date(year + '/' + month + '/' + day);
  const selectedMonth: number = dateOfBirth.getMonth() + 1;
  const selectedDate: number = dateOfBirth.getDate();

  const yearToMatch: number = dateOfBirth.getFullYear();
  const monthToMatch: number = selectedMonth;
  const dayToMatch: number = selectedDate;

  const numberYearValue: number = Number(year);
  const numberMonthValue: number = Number(month);
  const numberDayValue: number = Number(day);

  if (
    year.length === 4 &&
    yearToMatch === numberYearValue &&
    monthToMatch === numberMonthValue &&
    dayToMatch === numberDayValue
  ) {
    const today: Date = new Date();
    let age: number = today.getFullYear() - dateOfBirth.getFullYear();
    const monthValue: number = today.getMonth() - dateOfBirth.getMonth();

    if (
      (monthValue < 0 && age === MIN_AGE) ||
      (monthValue === 0 && age === MIN_AGE && today.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    } else if (
      (monthValue > 0 && age === MAX_AGE) ||
      (monthValue === 0 && age === MAX_AGE && today.getDate() > dateOfBirth.getDate())
    ) {
      age++;
    }

    return age < MIN_AGE ? 'underAgeError' : age > MAX_AGE ? 'overAgeError' : '';
  } else {
    return 'invalidDateError';
  }
};

const validateAddressField = (
  selectedCountry: Countries | string,
  value: string | undefined,
  field: AddressFieldKeys
) => {
  const addressFields = CountryFieldMapping[selectedCountry]
    ? CountryFieldMapping[selectedCountry]
    : CountryFieldMapping['default'];

  return !(addressFields && addressFields[field].visible && value?.trim() === '');
};

const testAddressFields = (field: AddressFieldKeys) => (addressField: any, context: any) => {
  const { from } = context as yup.TestContext & TestContextExtended;
  const { countryOfResidence } = from[1].value;

  return validateAddressField(countryOfResidence, addressField, field);
};

const shouldValidateIndustryData = (employmentStatusData: EmploymentStatusType[], employmentStatus: string) => {
  const selectedData = employmentStatusData.find((empStatus) => empStatus.name === employmentStatus);
  return selectedData ? selectedData.isIndustryDependent : true;
};

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { employmentStatusData } = useSelector(getReferenceData);

  const CORRequired: string = translate('registration.enterDetails.countryOfResidence.error.required');
  const serviceUnavailable: string = translate('registration.enterDetails.stateLicenseStatus.error.serviceUnavailable');
  const addressLine1Required: string = translate('registration.enterDetails.addressSearch.error.addressLine1');
  const suburbCityRequired: string = translate('registration.enterDetails.addressSearch.error.suburbCity');
  const provinceRequired: string = translate('registration.enterDetails.addressSearch.error.province');
  const townCityRequired: string = translate('registration.enterDetails.addressSearch.error.townCity');
  const stateRequired: string = translate('registration.enterDetails.addressSearch.error.state');
  const postcodeRequired: string = translate('registration.enterDetails.addressSearch.error.postcode');
  const dobDayRequired: string = translate('registration.enterDetails.dob.day.error.required');
  const dobDayMax: string = translate('registration.enterDetails.dob.day.error.max');
  const dobDayRange: string = translate('registration.enterDetails.dob.day.error.range');
  const dobMonthRequired: string = translate('registration.enterDetails.dob.month.error.required');
  const dobMonthMax: string = translate('registration.enterDetails.dob.month.error.max');
  const dobMonthRange: string = translate('registration.enterDetails.dob.month.error.range');
  const dobYearRequired: string = translate('registration.enterDetails.dob.year.error.required');
  const dobYearMin: string = translate('registration.enterDetails.dob.year.error.min');
  const dobYearMax: string = translate('registration.enterDetails.dob.year.error.max');
  const dobUnderAge: string = translate('registration.enterDetails.dob.error.underAge');
  const dobOverAge: string = translate('registration.enterDetails.dob.error.overAge');
  const invalidDate: string = translate('registration.enterDetails.dob.error.invalidDate');
  const occupationRequired: string = translate('registration.enterDetails.occupation.error.required');
  const occupationMax: string = translate('registration.enterDetails.occupation.error.max');
  const occupationAlphaNum: string = translate('registration.enterDetails.occupation.error.alphaNum');
  const employmentStatusRequired: string = translate(
    'registration.enterDetails.employment.employmentStatus.error.required'
  );
  const employmentIndustryRequired: string = translate(
    'registration.enterDetails.employment.employmentIndustry.error.required'
  );
  const SSNRequired: string = translate('registration.enterDetails.usa.socialSecurityNumber.error.required');
  const SSNMinRequired: string = translate('registration.enterDetails.usa.socialSecurityNumber.error.min');
  const SSNNumeric: string = translate('registration.enterDetails.usa.socialSecurityNumber.error.numeric');
  const nieNifTaxIdNumberRequired: string = translate('registration.enterDetails.esp.nieNifTaxIdNumber.error.required');
  const nieNifTaxIdNumberInvalid: string = translate('registration.enterDetails.esp.nieNifTaxIdNumber.error.invalid');
  const agreeTCRequired: string = translate('registration.enterDetails.agreeTC.error.required');

  const dayRangeRegex: RegExp = /\b(0?[1-9]|[1-2][0-9]|3[0-1])\b/;
  const monthRangeRegex: RegExp = /\b(0?[1-9]|1[0-2])\b/;
  const alphaNumRegex: RegExp = /^[a-zA-Z0-9 ]*$/;
  const numRegex: RegExp = /^[0-9]*$/;
  const nieNifTaxIdNumberRegex: RegExp = /^([a-zA-Z0-9]{9})$/;

  return yup.object({
    // Country of residence (COR)
    countryOfResidence: yup.string().trim().required(CORRequired),
    // USA state check
    stateLicenseStatus: yup.number().when('countryOfResidence', {
      is: (countryOfResidence: string) => {
        return LICENSE_STATUS_COUNTRIES.includes(countryOfResidence?.trim().toLowerCase());
      },
      then: yup.number().test('validate-stateLicenseStatus', serviceUnavailable, (value) => value !== 0)
    }),
    // Address
    address: yup
      .object()
      .shape({
        addressLine1: yup
          .string()
          .test('validate-addressLine1', addressLine1Required, testAddressFields('addressLine1')),
        suburbCity: yup.string().test('validate-suburbCity', suburbCityRequired, testAddressFields('suburbCity')),
        province: yup.string().test('validate-province', provinceRequired, testAddressFields('province')),
        townCity: yup.string().test('validate-townCity', townCityRequired, testAddressFields('townCity')),
        state: yup.string().test('validate-state', stateRequired, testAddressFields('state')),
        postcode: yup.string().test('validate-postcode', postcodeRequired, testAddressFields('postcode'))
      })
      .nullable()
      .default(null),
    // Date of birth
    dob: yup
      .object()
      .shape({
        day: yup.string().trim().required(dobDayRequired).max(2, dobDayMax).matches(dayRangeRegex, dobDayRange),
        month: yup
          .string()
          .trim()
          .required(dobMonthRequired)
          .max(2, dobMonthMax)
          .matches(monthRangeRegex, dobMonthRange),
        year: yup.string().trim().required(dobYearRequired).min(4, dobYearMin).max(4, dobYearMax)
      })
      .test({
        name: 'validate-dob',
        test: function (dobValue) {
          const { day, month, year } = dobValue;
          if (
            day?.length &&
            month?.length &&
            year?.length === 4 &&
            day.match(dayRangeRegex) &&
            month.match(monthRangeRegex)
          ) {
            const responseMessage = validateAge(day, month, year);
            return responseMessage !== ''
              ? this.createError({
                  message:
                    responseMessage === 'invalidDateError'
                      ? invalidDate
                      : responseMessage === 'underAgeError'
                      ? dobUnderAge
                      : dobOverAge
                })
              : true;
          }
          return true;
        }
      }),

    // Default occupation
    occupation: yup.string().when('countryOfResidence', {
      is: (countryOfResidence: string) => {
        return !showEmploymentFields;
      },
      then: yup
        .string()
        .trim()
        .required(occupationRequired)
        .max(50, occupationMax)
        .matches(alphaNumRegex, occupationAlphaNum)
    }),

    // Employment status
    employmentStatus: yup.string().test('employment-status', employmentStatusRequired, function (value) {
      if (showEmploymentFields) {
        return !!value;
      }
      return true;
    }),

    // Employment Industry
    employmentIndustry: yup.string().when('employmentStatus', {
      is: (employmentStatus: string) => {
        return showEmploymentFields && shouldValidateIndustryData(employmentStatusData, employmentStatus);
      },
      then: yup.string().trim().required(employmentIndustryRequired)
    }),

    // Agree TnC and Privacy policy
    agreeTC: yup
      .bool()
      .nullable()
      .test('agreeTC-validate', agreeTCRequired, function (value) {
        if (value !== null) {
          return !!value;
        }
        return true;
      }),

    // COR = USA
    usa: yup
      .object()
      .shape({
        // Social security number
        socialSecurityNumber: yup
          .string()
          .trim()
          .required(SSNRequired)
          .min(9, SSNMinRequired)
          .matches(numRegex, SSNNumeric)
      })
      .nullable()
      .default(null),

    // COR = SPAIN
    esp: yup
      .object()
      .shape({
        // NIE/NIF tax ID number
        nieNifTaxIdNumber: yup
          .string()
          .trim()
          .required(nieNifTaxIdNumberRequired)
          .matches(nieNifTaxIdNumberRegex, nieNifTaxIdNumberInvalid)
      })
      .nullable()
      .default(null)
  });
};
