export type TagObject = {
  UA?: string;
  GA4: string;
};

export type ActionsType = {
  click: TagObject;
  load: TagObject;
  select: TagObject;
};

export type ActionKeys = 'click_item' | 'load_item' | 'selected_item';

export type PageTagObjectType = {
  click: { [keyName: string]: TagObject };
  load: { [keyName: string]: TagObject };
  select: { [keyName: string]: TagObject };
};

export type GoogleAnalyticsTagsMappingType = {
  eventAction: ActionsType;
  eventActionKey: { [keyName: string]: ActionKeys };
  eventName: {
    pfx_registration_name: TagObject;
  };
  createYourLogin: PageTagObjectType;
  setupSecurityNumber: PageTagObjectType;
  enterSecurityCode: PageTagObjectType;
  enterYourDetails: PageTagObjectType;
  setYourPreferences: PageTagObjectType;
  verificationTimer: PageTagObjectType;
  kycFailedDocument: PageTagObjectType;
  stp: PageTagObjectType;
  registrationPending: PageTagObjectType;
  landingPage: PageTagObjectType;
  referAFriendPage: PageTagObjectType;
};

export type EventObject = {
  event: string;
  event_name: string;
  click_item?: string;
  load_item?: string;
  selected_item?: string;
  Legal_entity_type?: string;
  user_id?: string;
};

export type EventUAObject = {
  event_category: string;
  event_action: string;
  event_label: string;
  event: string;
};

export type EventAction = { [keyName: string]: string };

export type EventLabel = { [keyName: string]: string };

export enum LinkType {
  PP = 'Privacy and Policy',
  TNC = 'Terms and Conditions',
  AndroidStore = 'Android Store',
  IOSStore = 'Ios Store'
}

export type PoiTagsMapping =
  | 'passport'
  | 'nationalIdCard'
  | 'fullUkDrivingLicence'
  | 'residencePermit'
  | 'firearmsCertificate'
  | 'residencePermitGreenCard'
  | 'fullStateDrivingLicence'
  | 'fullDriversLicence'
  | 'governmentIssuedIdCard'
  | 'foreignIdentityCard'
  | 'residenceCard'
  | 'fullDriversProbLicense'
  | 'internationalDrivingPermit'
  | 'citizensCertificate'
  | 'ageProofCard';

export type PoaTagsMapping =
  | 'fullUkDrivingLicence'
  | 'householdUtilityBill'
  | 'telephoneBill'
  | 'bankBuildingSocietyStatement'
  | 'councilTaxOrLocalAuthorityBill'
  | 'statePensionLetter'
  | 'mortgageStatement'
  | 'statementOfBenefitsEntitlement'
  | 'taxOrGovIssuedDocument'
  | 'northernIrelandVotersCard'
  | 'proofOfInsurance'
  | 'propertyLease'
  | 'mobilePhoneBill'
  | 'poBoxReference'
  | 'fullStateDrivingLicence'
  | 'bankStatement'
  | 'govBill'
  | 'vehicleRegistration'
  | 'telephoneBillLandline'
  | 'telephoneBillMobile'
  | 'visa'
  | 'certifiedBirthCertificate'
  | 'benefitsStatement'
  | 'pensionsDocument'
  | 'firearmsLicense'
  | 'taxationAssessment'
  | 'rentalAgreement'
  | 'taxCorrOrGovIssuedDoc';
