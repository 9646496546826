import { FC, useContext, MouseEvent, KeyboardEvent, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../../atoms/RPText';
import RPLink from '../../../../atoms/RPLink';
import { RPButton, RPButtonContainer } from '../../../../atoms/RPButton';
import { SetupSecurityViewType } from '../SetupSecurity';
// Hooks
import { useStepTypeData } from '../../../../../core/hooks';
// RTK Slice
import { getRegistrationDetails, resetOTPValidationValue } from '../../../../../redux/modules/registrationDetailsSlice';
import { updateCurrentStep } from '../../../../../redux/modules/registrationStepsSlice';
// API Wrappers
import verifyEtvExist, { etvPayloadData } from '../../../../../redux/api/verifyEtvExist';
// Utils
import { getIcon } from '../../../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { extractDialingCodeValue } from '../../../../../core/utils/DialingData';
import { getFormattedMobileNumber } from '../../../../../core/utils/GetFormattedMobileNumber';
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { ENTER_KEY, MASK_CLASS, STEPS_ROUTE_MAPPING, USA } from '../../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../../RegistrationDefinitions';

interface CompletedScreenProps {
  setViewType: (viewType: SetupSecurityViewType) => void;
}

const StyledHeading = styled(RPText)(() => {
  return {
    marginBottom: 40,
    textAlign: 'center'
  };
});

const StyledLinkWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column'
  };
});

const StyledIllustrationWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    marginBottom: 20
  };
});

const CompletedScreen: FC<CompletedScreenProps> = (props: CompletedScreenProps) => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { tenant } = useContext(TenantContext);

  const {
    countryCode,
    mobileNumber,
    email,
    queryCOR: queryCORValue,
    verifyETVLoading
  } = useSelector(getRegistrationDetails);

  const { previousStepType, nextStepType } = useStepTypeData('setup-security');

  const headingText: string = translate('registration.setupSecurity.completedScreen.heading');

  const buttonText: string = translate('registration.setupSecurity.completedScreen.continueButtonText');

  const backLinkText: string = translate('registration.links.backToStart');

  const changeNumberText: string = translate('registration.setupSecurity.completedScreen.changeNumber');

  const { setViewType } = props;

  const WelcomeCardInHandIcon = useMemo(() => getIcon(tenant, 'welcomeCardInHand'), [tenant]);

  const buttonSize: 'medium' | 'large' = isMobileLayoutApplicable(layout) ? 'medium' : 'large';
  const headingVariant: 'h2' | 'h3' = layout === 'desktop' ? 'h2' : 'h3';

  const {
    setupSecurity: {
      completedScreen: {
        continueButton: { name: continueButtonName, dataTestId: continueButtonDataTestId },
        backButton: { name: backButtonName, dataTestId: backButtonDataTestId },
        changeNumber: { dataTestId: changeNumberLinkDataTestId }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const corIsUSA: boolean = queryCORValue?.toLowerCase() === USA;

  useEffect(() => {
    if (verifyETVLoading === 'succeeded') {
      dispatch(
        updateCurrentStep({
          stepType: nextStepType
        })
      );

      navigate(STEPS_ROUTE_MAPPING[nextStepType]);
    }
  }, [verifyETVLoading]);

  const navigateToNextPage = () => {
    const payloadData: etvPayloadData = {
      countryCode: extractDialingCodeValue(countryCode),
      mobileNumber,
      email,
      corIsUSA
    };
    dispatch(verifyEtvExist(payloadData));
  };

  const navigateToBackPage = () => {
    dispatch(
      updateCurrentStep({
        stepType: previousStepType
      })
    );

    navigate(STEPS_ROUTE_MAPPING[previousStepType]);
  };

  const handleChangeNumber = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(resetOTPValidationValue());
    setViewType('mobile-number-input');
  };

  const formattedMobileNumber: string = getFormattedMobileNumber(countryCode, mobileNumber);

  return (
    <>
      <StyledHeading variant={headingVariant} type="bold" text={headingText} />
      <StyledIllustrationWrapper>
        <WelcomeCardInHandIcon />
      </StyledIllustrationWrapper>
      <StyledLinkWrapper>
        <RPText
          variant="body2"
          type="bold"
          text={`${extractDialingCodeValue(countryCode) + ' ' + formattedMobileNumber}`}
          className={MASK_CLASS}
        />
        <RPLink
          underline="hover"
          type="normal"
          text={changeNumberText}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => handleChangeNumber(e)}
          onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
            e.key === ENTER_KEY && handleChangeNumber(e);
          }}
          href=""
          data-testid={changeNumberLinkDataTestId}
        />
      </StyledLinkWrapper>

      <RPButtonContainer layout={layout}>
        <RPButton
          size={buttonSize}
          type="submit"
          onClick={navigateToNextPage}
          name={continueButtonName}
          data-testid={continueButtonDataTestId}
        >
          {buttonText}
        </RPButton>

        <RPButton
          size={buttonSize}
          btnType="text"
          onClick={navigateToBackPage}
          name={backButtonName}
          data-testid={backButtonDataTestId}
        >
          {backLinkText}
        </RPButton>
      </RPButtonContainer>
    </>
  );
};

export default CompletedScreen;
