import { OrgIconMapping, IconObject } from '../../types/IconOrgTypes';
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
import { CDIconObject } from '../../TenantProvider/tenants/currenciesdirect/cdIcons';
import { TorFxIconObject } from '../../TenantProvider/tenants/torfx/torfxIcons';
import { TENANTS } from '../Constants/Constants';

export const ICON_ORG_MAPPING: OrgIconMapping = {
  currenciesdirect: CDIconObject,
  torfx: TorFxIconObject,
  torfxoz: TorFxIconObject,
  fcg: CDIconObject,
  ramsdens: CDIconObject,
  hl: CDIconObject
};

export const getIcon = (tenant: OrganizationCodeTypes | undefined, name: keyof IconObject) => {
  return tenant
    ? ICON_ORG_MAPPING[tenant][name]!
    : ICON_ORG_MAPPING[TENANTS.CURRENCIES_DIRECT][name]!;
};
