// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Constants
import {
  ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG,
  CD_CONTACT_US_URL,
  TORFX_CONTACT_US_URL,
  TORFXOZ_CONTACT_US_URL
} from '../Constants/Constants';

type ContactUsLinkType = {
  contactUsURL: string
};

export const CONTACT_US_LINKS_ORG_MAPPING: { [K in OrganizationCodeTypes]: ContactUsLinkType } = {
  currenciesdirect: {
    contactUsURL: CD_CONTACT_US_URL
  },
  torfx: {
    contactUsURL: TORFX_CONTACT_US_URL
  },
  torfxoz: {
    contactUsURL: TORFXOZ_CONTACT_US_URL
  },
  fcg: {
    contactUsURL: ''
  },
  ramsdens: {
    contactUsURL: ''
  },
  hl: {
    contactUsURL: ''
  }
};

const GetContactUsLink = (organizationCode: OrganizationCodeTypes | undefined): string => {
  const DEFAULT_ORGANIZATION_CODE: OrganizationCodeTypes = ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode;
  const CURRENT_ORGANIZATION_CODE: OrganizationCodeTypes = organizationCode || DEFAULT_ORGANIZATION_CODE;

  return CONTACT_US_LINKS_ORG_MAPPING[CURRENT_ORGANIZATION_CODE].contactUsURL;
};

export default GetContactUsLink;
