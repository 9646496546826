import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

// Contexts
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPLoader } from '../../atoms/RPLoader';
import FullLayout from '../../organisms/FullLayout';
import { SplitLayout } from '../../organisms/SplitLayout';
// Hooks
import useGetUrlParamValue from '../../../core/hooks/useGetUrlParamValue';
// Types
import { RegistrationReturnInactiveProps } from '../../../core/types/RegistrationReturnTypes/RegistrationReturnTypes';
import { LayoutSize } from '../../../core/types/LayoutTypes';
// RTK Slice
import { getAuthorisationDetails } from '../../../redux/modules/authorisationDetailsSlice';
import { removePreferencesStep, updateCurrentStep } from '../../../redux/modules/registrationStepsSlice';
import {
  getRegistrationDetails,
  resetComplianceStatusLoading,
  setHideLiveChatIcon
} from '../../../redux/modules/registrationDetailsSlice';
import { setSelectedLanguage } from '../../../redux/modules/referenceDataSlice';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// API Wrappers
import registerDevice from '../../../redux/api/registerDevice';
import generateToken, { GenerateTokenData } from '../../../redux/api/generateToken';
import getCustomerDetailsByEmailToken from '../../../redux/api/getCustomerDetailsByEmailToken';
// Constants
import {
  LANG_CODE_MAPPING,
  REGISTRATION_RETURN_CURRENT_LANGUAGE_QUERY_KEY,
  REGISTRATION_RETURN_EMAIL_TOKEN_QUERY_KEY,
  REGISTRATION_RETURN_INACTIVE_KYC_FAIL_PAGE,
  REGISTRATION_RETURN_KYC_FAIL_PAGE
} from '../../../core/utils/Constants/Constants';
//redux
import { removePersistedStoreData } from '../../../redux/store';
import { resetStoreToInitialState } from '../../../redux/actions/resetStore';
import { RESET_STORE_TO_INITIAL_STATE } from '../../../redux/ActionTypeConstants';

/*const Container = styled(Box)(() => {
  return {
    width: '100%',
    height: 'calc(100vh)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  };
});*/

interface LayoutProps {
  layout: LayoutSize;
}

interface RegistrationReturnComponentProps extends RegistrationReturnInactiveProps {}

const LoaderContainer = styled(Box)<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginTop: isMobileLayout ? '130px' : '0px'
  };
});

const RegistrationReturn: FC<RegistrationReturnComponentProps> = ({ isRegistrationReturnInactive = false }) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { layout } = useContext(LayoutContext);
  const { language, setLanguage } = useContext(LanguageContext);

  const emailToken: string | null = useGetUrlParamValue(REGISTRATION_RETURN_EMAIL_TOKEN_QUERY_KEY);
  const currentLanguage: string | null = useGetUrlParamValue(REGISTRATION_RETURN_CURRENT_LANGUAGE_QUERY_KEY);
  // const emailToken: string | null = '241ed7c6-cc93-4634-a3d8-9a38c1cc7e2b';

  console.log('RegistrationReturn emailToken: ', emailToken);

  let dataFetchedRef = useRef(false);
  let langDataFetchedRef = useRef(false);

  const { registerDeviceLoading, generateTokenLoading, clientId, clientSecret } = useSelector(getAuthorisationDetails);
  const { complianceStatusLoading } = useSelector(getRegistrationDetails);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (langDataFetchedRef.current) return;
    if (currentLanguage) {
      const EN_GB: string = LANG_CODE_MAPPING.EN;
      const currentLanguageLocaleValue = LANG_CODE_MAPPING[currentLanguage.toUpperCase()];
      if (currentLanguageLocaleValue !== (language || EN_GB)) {
        langDataFetchedRef.current = true;
        setLanguage(currentLanguageLocaleValue);
        dispatch(setSelectedLanguage(currentLanguageLocaleValue));
      }
    }
  }, [currentLanguage, language]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (isRegistrationReturnInactive) {
      removePersistedStoreData();
      dispatch(resetStoreToInitialState(RESET_STORE_TO_INITIAL_STATE));
    }

    resetComplianceStatusLoading();

    if (registerDeviceLoading === 'idle' && (clientId === '' || clientSecret === '')) {
      dispatch(registerDevice(1));
    }
    dispatch(removePreferencesStep());
    dispatch(
      updateCurrentStep({
        stepType: 'confirming-you'
      })
    );
  }, [registerDeviceLoading, clientId, clientSecret]);

  useEffect(() => {
    console.log('useEffect generateToken 1 registerDeviceLoading: ', registerDeviceLoading);
    console.log('useEffect generateToken 1 generateTokenLoading: ', generateTokenLoading);
    if (registerDeviceLoading === 'succeeded' && generateTokenLoading !== 'loading') {
      const generateTokenData: GenerateTokenData = {
        clientId,
        clientSecret,
        attempt: 1
      };

      dispatch(generateToken(generateTokenData));
    }
  }, [registerDeviceLoading, generateTokenLoading]);

  useEffect(() => {
    if (generateTokenLoading === 'succeeded' && complianceStatusLoading === 'idle') {
      dispatch(getCustomerDetailsByEmailToken({ emailToken: emailToken || '' }));
    }
  }, [generateTokenLoading]);

  useEffect(() => {
    if (complianceStatusLoading === 'succeeded') {
      setIsLoading(false);
      dispatch(setHideLiveChatIcon());
      navigate(
        isRegistrationReturnInactive ? REGISTRATION_RETURN_INACTIVE_KYC_FAIL_PAGE : REGISTRATION_RETURN_KYC_FAIL_PAGE
      );
    }
  }, [complianceStatusLoading]);

  const children: JSX.Element = (
    <>
      {isLoading ? (
        <LoaderContainer layout={layout}>
          <RPLoader size="large" type="secondary" />
        </LoaderContainer>
      ) : (
        <div>Registration Return</div>
      )}
    </>
  );

  return isRegistrationReturnInactive ? (
    <FullLayout
      layoutType="old"
      hideAdditionalInteractions
      stickHeaderAtTop={false}
      shouldAddWrapperContainerStyling={true}
    >
      {children}
    </FullLayout>
  ) : (
    <SplitLayout layoutVariant={'partial'}>{children}</SplitLayout>
  );
};

export default RegistrationReturn;
