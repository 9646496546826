import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Contexts
import { LanguageContext } from '../TenantProvider/contexts';
// Hooks
import useGetUrlParamValue from './useGetUrlParamValue';
// Types
import { CountryDataInfo } from '../types/CountryDataTypes';
// RTK Slice
import {
  addCountryOfResidence,
  getRegistrationDetails,
  resetAddressData,
  setQueryCORValue
} from '../../redux/modules/registrationDetailsSlice';
import { getAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
import { resetSteps, removePreferencesStep } from '../../redux/modules/registrationStepsSlice';
import { getReferenceData } from '../../redux/modules/referenceDataSlice';
// API Wrappers
import getCountryData from '../../redux/api/getCountryData';
// Utils
import { CheckValidCOR } from '../utils/CheckValidCOR/CheckValidCOR';
// Constants
import { LANG_CODE_MAPPING, LOCALE_LANG_CODE_MAPPING, USA } from '../utils/Constants/Constants';

const useCORValueFromUrl = (name: string) => {
  const urlCORValue: string | null = useGetUrlParamValue(name);

  let dataFetchedRef = useRef(false);

  const dispatch: any = useDispatch();

  const { language } = useContext(LanguageContext);

  const { countryData, languageForCountryData } = useSelector(getReferenceData);
  const { countryOfResidence } = useSelector(getRegistrationDetails);
  const { generateTokenLoading } = useSelector(getAuthorisationDetails);

  useEffect(() => {
    if (generateTokenLoading === 'succeeded') {
      if (dataFetchedRef.current) return;

      dataFetchedRef.current = true;

      if (urlCORValue && urlCORValue?.trim() !== '') {
        // Get country data if it doesn't exist in the store
        (!countryData || countryData.length === 0 || languageForCountryData !== (language || LANG_CODE_MAPPING.EN) ) &&
          dispatch(getCountryData({
            locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
          }));

        // Remove Set your preferences if COR = USA
        if (urlCORValue?.trim().toLowerCase() === USA) {
          dispatch(removePreferencesStep());
        } else {
          dispatch(resetSteps());
        }
      }
    }
  }, [generateTokenLoading]);

  useEffect(() => {
    if (countryData && countryData.length > 0) {
      // Check if url value is not null, blank and not same as COR value from the store
      const trimmedUrlCORValue: string | undefined = urlCORValue?.trim().toLowerCase();
      if (
        trimmedUrlCORValue &&
        trimmedUrlCORValue !== '' &&
        countryOfResidence?.trim().toLowerCase() !== trimmedUrlCORValue
      ) {
        // Check if url COR value exists in the reference data
        const validCOR: CountryDataInfo | undefined = CheckValidCOR(countryData, trimmedUrlCORValue);
        if (validCOR) {
          // Set COR code and name to the store and reset address field
          const payload = {
            countryOfResidence: validCOR.value,
            countryOfResidenceName: validCOR.name
          };
          dispatch(addCountryOfResidence(payload));
          dispatch(resetAddressData());
          // Set query COR code
          dispatch(setQueryCORValue(validCOR.value));
        }
      }
    }
  }, [countryData]);
};

export default useCORValueFromUrl;
