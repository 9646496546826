import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';

// Contexts
import { LanguageContext, LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import { RPSelectNew } from '../../../atoms/RPSelect';
// Types
import { EnterDetailsType } from '../../../../core/types/RegistrationStepForm';
import { OptionType } from '../../../../core/types/SelectTypes';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';

interface EmploymentFieldsProps {
  getFieldHeading: (labelText: string) => JSX.Element;
  control: Control<EnterDetailsType, any>;
  employmentStatus: string;
  handleEmploymentStatusChange: (value: string) => void;
  showEmploymentIndustryDropdown: boolean;
  employmentIndustry: string;
}

const {
  enterDetails: {
    employmentStatus: { name: employmentStatusFieldName },
    employmentIndustry: { name: employmentIndustryFieldName }
  }
} = REGISTRATION_DEFINITIONS;

const EmploymentFields: FC<EmploymentFieldsProps> = (props: EmploymentFieldsProps) => {
  const {
    getFieldHeading,
    control,
    employmentStatus,
    handleEmploymentStatusChange,
    showEmploymentIndustryDropdown,
    employmentIndustry
  } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { employmentStatusData, employmentIndustryData } = useSelector(getReferenceData);

  const employmentHeading = translate('registration.enterDetails.employment.heading');
  const employmentStatusLabel = translate('registration.enterDetails.employment.employmentStatus.label');
  const employmentIndustryLabel = translate('registration.enterDetails.employment.employmentIndustry.label');

  const employmentStatusOptions: OptionType[] = employmentStatusData.map((data) => {
    return {
      value: data.name,
      label: data.displayName
    };
  });

  const employmentIndustryOptions: OptionType[] = employmentIndustryData.map((data) => {
    return {
      value: data.name,
      label: data.displayName
    };
  });

  const shouldShowMaxOptions: boolean = !isMobileLayoutApplicable(layout);

  return (
    <>
      {getFieldHeading(employmentHeading)}
      <ControllerElementWrapper
        name={employmentStatusFieldName}
        control={control}
        defaultValue={employmentStatus}
        options={employmentStatusOptions}
        placeholderLabel={employmentStatusLabel}
        component={RPSelectNew}
        size="normal"
        handleChange={(value: string) => handleEmploymentStatusChange(value)}
        showMaxOptions={shouldShowMaxOptions}
        isMobileOptimised={true}
      />

      <Collapse easing="300" in={showEmploymentIndustryDropdown}>
        <ControllerElementWrapper
          name={employmentIndustryFieldName}
          control={control}
          defaultValue={employmentIndustry}
          options={employmentIndustryOptions}
          placeholderLabel={employmentIndustryLabel}
          component={RPSelectNew}
          size="normal"
          showMaxOptions={shouldShowMaxOptions}
          isMobileOptimised={true}
        />
      </Collapse>
    </>
  );
};

export default EmploymentFields;
