import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectOnce } from 'usehooks-ts';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Collapse } from '@mui/material';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import { RPButton } from '../../atoms/RPButton';
import { RPSelectNew } from '../../atoms/RPSelect';
import { RHFRPRadioListButton } from '../../atoms/RPRadioButton';
import { RPConfirmationModal } from '../../molecules/RPModals';
import { RPInformationalBlurb } from '../../molecules/RPInformationalBlurb';
import { PartialService } from '../../molecules/PartialService';
import { NoService } from '../../molecules/NoService';
import { CFSBNoService } from '../../molecules/CFSBNoService';
import { SplitLayout } from '../../organisms/SplitLayout';
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import {
  useRegisterDeviceAndGenerateToken,
  useLanguageCookieAndIPCheck,
  useSetAffiliateCookie
} from '../../../core/hooks';
// Types
import { OptionType } from '../../../core/types/SelectTypes';
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { CountryDataInfo } from '../../../core/types/CountryDataTypes';
import {
  CountryCodesWithStateDropdown,
  StateInfo,
  StateLicenseStatusType
} from '../../../core/types/CountryStateTypes';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
import { InformationalBlurbContent } from '../../molecules/RPInformationalBlurb/RPInformationalBlurb';
// RTK Slice
import { getAuthorisationDetails } from '../../../redux/modules/authorisationDetailsSlice';
import { getReferenceData } from '../../../redux/modules/referenceDataSlice';
import {
  addCountryOfResidence,
  addstateOfCountryOfResidence,
  getRegistrationDetails,
  updateZarModalOpenStatus
} from '../../../redux/modules/registrationDetailsSlice';
// API Wrappers
import getCountryData from '../../../redux/api/getCountryData';
import getCountryStateData from '../../../redux/api/getCountryStateData';
// Utils
import { getConfigProperty } from '../../../core/EnvironmentConfig/EnvironmentConfig';
import { GetBasicDetails } from '../../../core/utils/GetBasicDetails';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { CheckValidCOR } from '../../../core/utils/CheckValidCOR/CheckValidCOR';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getCountryName, getStateName } from '../../../core/utils/CountryData';
import { getInformationalBlurbContentArray } from '../../../core/utils/GetInformationalBlurbContentArray';
import { checkIfCFSBIsEnabled } from '../../../core/utils/CheckIfCFSBIsEnabled';
// Constants
import {
  CD_SA_URL,
  LANG_CODE_MAPPING,
  LOCALE_LANG_CODE_MAPPING,
  ORGANIZATION_CODE_MAPPING,
  ORGANIZATION_STATE_DROPDOWN_MAPPING,
  SESSION_EXPIRED_LS_KEY,
  SESSION_TIMER_MODAL_LS_KEY,
  SHOW_ZAR_MODAL_ORGANIZATIONS_LIST,
  STEPS_ROUTE_MAPPING,
  ZAF,
  ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_LANDING,
  CFSB_INFORMATIONAL_BLURB_APPLICABLE_ORGANIZATIONS,
  CFSB_USA_NO_SERVICE_ORGANISATION_LIST,
  INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION
} from '../../../core/utils/Constants/Constants';
import { RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE } from '../../../redux/ActionTypeConstants';
import { resetStoreToInitialStateFromLandingPage } from '../../../redux/actions/resetStore';
// GA4
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
// Feature Flags
import { featureFlags } from '../../../core/FeatureFlags/FeatureFlags';

interface LayoutProps {
  layout: LayoutSize;
}

export enum AccountTypes {
  individual = 'individual',
  business = 'business'
}

export type RegistrationAccountType = AccountTypes.individual | AccountTypes.business;

interface RegisterPageType {
  accountType: RegistrationAccountType;
  countryOfResidence: string;
  selectedState: string;
}

interface StyledHeadingProps extends LayoutProps {}

interface ControllerElementWrapperContainerProps extends LayoutProps {
  showStateDropDown: boolean;
  showCFSBInformationBlurb: boolean;
}

const Container = styled(Box)<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    width: isMobileLayout ? '325px' : '420px',
    '& .MuiButton-root': {
      width: '100%',
      marginTop: isMobileLayout ? '30px' : '60px'
    },
    '& .MuiTypography-h2': {
      marginBottom: '40px'
    },
    '>form>div:nth-child(1), >form>div:nth-child(2)': {
      padding: '18px 12px',
      marginBottom: '10px'
    }
  };
});

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '20px' : '40px'
  };
});

const AccountTypeIconContainer = styled('span')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    display: 'flex',

    '> svg': {
      width: '24px',
      height: '24px',

      ' > path': {
        fill: COLOUR_TEXT_TEXT_LINK
      }
    }
  };
});

const ControllerElementWrapperContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showStateDropDown' && prop !== 'showCFSBInformationBlurb'
})<ControllerElementWrapperContainerProps>(({ layout, showStateDropDown, showCFSBInformationBlurb }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginTop: isMobileLayout ? '20px' : '40px',
    ...(showStateDropDown && {
      marginBottom: '10px'
    }),
    ...(showCFSBInformationBlurb && {
      marginBottom: 16
    })
  };
});

const Register = () => {
  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  let countryDataFetchedRef = useRef(false);

  const { generateTokenLoading } = useSelector(getAuthorisationDetails);
  const {
    countryData,
    dialingCodeData,
    countryStateData,
    countryLoading,
    countryStateLoading,
    ipBasedCountryCode,
    locationLookupLoading,
    languageForCountryData
  } = useSelector(getReferenceData);
  const { countryOfResidence, stateOfCountryOfResidence, hasZARModalBeenOpened } = useSelector(getRegistrationDetails);

  useLanguageCookieAndIPCheck();
  useRegisterDeviceAndGenerateToken();

  const [stateDropdownOptions, setStateDropdownOptions] = useState<OptionType[]>([]);
  const [showStateDropDown, setShowStateDropDown] = useState<boolean>(false);
  const [shouldShowServiceWarning, setShouldShowServiceWarning] = useState<boolean>(false);
  const [cdLicenseStatus, setCdLicenseStatus] = useState<number>(2);
  const [isZARModalOpen, setIsZARModalOpen] = useState<boolean>(false);
  const [showCFSBInformationBlurb, setShowCFSBInformationBlurb] = useState<boolean>(false);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm<RegisterPageType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchema()),
    defaultValues: {
      countryOfResidence: countryOfResidence,
      selectedState: stateOfCountryOfResidence,
      accountType: AccountTypes.individual
    }
  });

  const { accountType, countryOfResidence: countryOfResidenceValue, selectedState } = watch();

  const LandingPageIndividualIcon = useMemo(() => getIcon(tenant, 'landingPageIndividualIcon'), [tenant]);
  const LandingPageBusinessIcon = useMemo(() => getIcon(tenant, 'landingPageBusinessIcon'), [tenant]);

  const InformationalBlurbContentArray: Array<InformationalBlurbContent> = useMemo(
    () => getInformationalBlurbContentArray(INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION, translate),
    [translate, language]
  );

  const { businessRegistrationCFXURL } = GetBasicDetails();

  const currentTenant: string = tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const isIndividualAccount: boolean = accountType === AccountTypes.individual;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const headingTextVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';
  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const {
    eventAction: { click, load, select },
    landingPage: {
      click: { selectAccountTypeIndividual, selectAccountTypeBusiness },
      load: { selectAccountTypeScreenLoaded },
      select: {
        accountTypeCOR: accountTypeCORGA,
        accountTypeState: accountTypeStateGA,
        accountTypeLanguage: accountTypeLanguageGA
      }
    }
  } = GoogleAnalyticsTagsMapping;

  const headingText: string = translate('registerPage.heading');
  const individualOptionLabelText: string = translate('registerPage.individualOptionLabelText');
  const businessOptionLabelText: string = translate('registerPage.businessOptionLabelText');
  const countryOfResidenceLabel: string = translate('registerPage.countryOfResidenceLabelText');
  const submitButtonText: string = translate('registerPage.submitButtonText');
  const corPlaceHolderText: string = translate('registerPage.corPlaceHolderText');
  const statePlaceHolderText: string = translate('registerPage.statePlaceHolderText');
  const ZARMessage: string = translate('modal.ZAR.heading');
  const ZARAdditionalMessage: string = translate('modal.ZAR.description');

  useLanguageCookieAndIPCheck();
  useRegisterDeviceAndGenerateToken();
  useSetAffiliateCookie();

  const ZARPrimaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobilePrimaryBtnLabel' : 'modal.ZAR.primaryBtnLabel'
  );

  const ZARSecondaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobileSecondaryBtnLabel' : 'modal.ZAR.secondaryBtnLabel'
  );

  const isCFSBEnabled: boolean = checkIfCFSBIsEnabled();

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, selectAccountTypeScreenLoaded);
    TriggerGoogleAnalyticsTag(click, selectAccountTypeIndividual);
  });

  useEffect(() => {
    if (
      ipBasedCountryCode &&
      locationLookupLoading === 'succeeded' &&
      ipBasedCountryCode === ZAF &&
      !hasZARModalBeenOpened &&
      tenant &&
      SHOW_ZAR_MODAL_ORGANIZATIONS_LIST.includes(tenant)
    ) {
      setIsZARModalOpen(true);
      dispatch(updateZarModalOpenStatus(true));
    }
  }, [ipBasedCountryCode, locationLookupLoading]);

  useEffect(() => {
    if (countryData.length > 0 && language === languageForCountryData) {
      return;
    }

    if (language !== languageForCountryData) {
      countryDataFetchedRef.current = false;
    }

    if (countryDataFetchedRef.current) return;

    if (generateTokenLoading === 'succeeded' && countryLoading !== 'loading') {
      countryDataFetchedRef.current = true;
      dispatch(
        getCountryData({
          locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
        })
      );
    }
  }, [generateTokenLoading, countryLoading, language]);

  useEffect(() => {
    if (countryData && countryData.length > 0 && ipBasedCountryCode !== '') {
      // Check if url value is not null, blank and not same as COR value from the store
      const trimmedCountryCodeValue: string = ipBasedCountryCode?.trim().toLowerCase();
      if (trimmedCountryCodeValue !== '') {
        // Check if url COR value exists in the reference data
        const validCOR: CountryDataInfo | undefined = CheckValidCOR(countryData, trimmedCountryCodeValue);

        if (validCOR) {
          // get Country code from dialingCodeData on the basis of COR value
          const validCountryCode = dialingCodeData.find(
            (data: any) => data.value.split('*')[1]?.trim().toLowerCase() === validCOR.value?.trim().toLowerCase()
          );
          // Update Country code to store
          if (validCountryCode) {
            // Set COR code and name to the store and reset address field
            const payload = {
              countryOfResidence: validCOR.value,
              countryOfResidenceName: validCOR.label
            };
            setValue('countryOfResidence', validCOR.value);
            dispatch(addCountryOfResidence(payload));
          }
        }
      }
    }
  }, [countryData, ipBasedCountryCode]);

  useEffect(() => {
    // check if the selected country is one of the countries with state options.
    // Check if country code from store is different from the selected one or
    // state data is empty

    const trimmedSelectedCountryForSearch = countryOfResidenceValue?.trim().toLowerCase();
    const OrganisationStateDropdownMappingObject: { [K in OrganizationCodeTypes]: Array<string> } = isCFSBEnabled
      ? ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_LANDING
      : ORGANIZATION_STATE_DROPDOWN_MAPPING;
    const isStateDropDownAvailable: boolean = OrganisationStateDropdownMappingObject[
      currentTenant as OrganizationCodeTypes
    ].includes(trimmedSelectedCountryForSearch);

    setShowStateDropDown(isStateDropDownAvailable);
    setShowCFSBInformationBlurb(
      isCFSBEnabled &&
        CFSB_INFORMATIONAL_BLURB_APPLICABLE_ORGANIZATIONS.includes(tenant!) &&
        trimmedSelectedCountryForSearch?.toUpperCase() === 'USA'
    );

    if (
      isStateDropDownAvailable &&
      (countryStateData.countryCode !== trimmedSelectedCountryForSearch || countryStateData.stateInfo.length === 0) &&
      countryStateLoading !== 'loading'
    ) {
      const requestData = {
        countryCode: trimmedSelectedCountryForSearch as CountryCodesWithStateDropdown,
        locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
      };

      // Dispatch action to make API call and fetch state details
      dispatch(getCountryStateData(requestData));
    }
  }, [countryOfResidenceValue, countryStateData, countryStateLoading]);

  useEffect(() => {
    // Create dropdown options for state
    if (countryStateData && countryStateData.stateInfo && countryStateData.stateInfo.length > 0) {
      const options: OptionType[] = countryStateData.stateInfo.map((data: StateInfo) => {
        return {
          value: data.code,
          label: data.displayName
        };
      });
      setStateDropdownOptions(options);
    }
  }, [countryStateData]);

  const getStateData = (stateValue: string) => {
    return (
      countryStateData &&
      countryStateData.stateInfo &&
      countryStateData.stateInfo.length > 0 &&
      countryStateData.stateInfo.find(
        (data: StateInfo) => data?.code?.trim().toLowerCase() === stateValue?.trim().toLowerCase()
      )
    );
  };

  const handleCORChange = (value: string) => {
    setValue('countryOfResidence', value);
    setValue('selectedState', '');

    const trimmedSelectedCountry: string = value.trim().toLowerCase();
    const isStateDropDownAvailable: boolean =
      ORGANIZATION_STATE_DROPDOWN_MAPPING[currentTenant as OrganizationCodeTypes].includes(trimmedSelectedCountry);
    if (
      stateDropdownOptions.length &&
      isStateDropDownAvailable &&
      countryStateData.countryCode !== trimmedSelectedCountry
    ) {
      setStateDropdownOptions([]);
    }
    if (cdLicenseStatus === 1 || cdLicenseStatus === 0) {
      setCdLicenseStatus(2);
    }
    if (value === ZAF && !hasZARModalBeenOpened && tenant && SHOW_ZAR_MODAL_ORGANIZATIONS_LIST.includes(tenant)) {
      setIsZARModalOpen(true);
      dispatch(updateZarModalOpenStatus(true));
    }
  };

  const handleStateChange = (value: string) => {
    console.log('handleStateChange', value);
    setValue('selectedState', value.trim());

    const stateData = getStateData(value);

    if (stateData) {
      const cdLicenseStatus: StateLicenseStatusType = stateData.cdLicenseStatus;
      setCdLicenseStatus(cdLicenseStatus);
    }
  };

  const resetStoreAndClearCookieData = () => {
    // Remove key for session-expired from local storage
    localStorage.removeItem(SESSION_EXPIRED_LS_KEY);
    // Remove key for session-timer-modal from local storage
    localStorage.removeItem(SESSION_TIMER_MODAL_LS_KEY);
    // Setting all store slices to initial state at once
    dispatch(resetStoreToInitialStateFromLandingPage(RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE));
  };

  const navigateToRegistrationPortal = (isNavigationToCD = false) => {
    addStateToStore();

    if (isNavigationToCD) {
      window.location.href = `${getConfigProperty('registrationPortalCurrenciesDirectURL') as string}?cor=USA&lang=en`;
      return;
    }

    // to clear store data and cookies before navigating to registration steps
    resetStoreAndClearCookieData();
    // navigate to registration portal
    navigate(
      `${STEPS_ROUTE_MAPPING['create-login']}/?cor=${countryOfResidenceValue}&lang=${
        LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
      }`
    );
  };

  const navigateToCFXPortal = () => {
    window.open(businessRegistrationCFXURL, '_self');
  };

  const partialServiceBtnClickHandler = () => {
    navigateToRegistrationPortal();
  };

  const noServiceBtnClickHandler = () => {
    setShouldShowServiceWarning(false);
  };

  const partialServiceBackBtnHandler = () => {
    setShouldShowServiceWarning(false);
  };

  const resetShowServiceWarningState = () => setShouldShowServiceWarning(false);

  const handleAccountTypeChange = (value: string) => {
    TriggerGoogleAnalyticsTag(
      click,
      value === AccountTypes.business ? selectAccountTypeBusiness : selectAccountTypeIndividual
    );

    setValue('accountType', value as RegistrationAccountType);
  };

  const navigateToCDSA = () => {
    window.open(CD_SA_URL, '_self');
  };

  const addStateToStore = () => {
    // add selected state (ex: California) to store
    const payload = {
      stateOfCountryOfResidence: selectedState
    };
    dispatch(addstateOfCountryOfResidence(payload));
  };

  const onSubmit: SubmitHandler<RegisterPageType> = (data) => {
    console.log(
      "countryOfResidence === 'USA' && CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!) : ",
      countryOfResidenceValue === 'USA' && CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!)
    );
    console.log(
      'CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!) : ',
      CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!)
    );

    const disableForCFSB: boolean =
      isCFSBEnabled && countryOfResidenceValue === 'USA' && CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!);

    if (accountType === AccountTypes.individual) {
      if (cdLicenseStatus === 2 && !disableForCFSB) {
        console.log('onSubmit countryOfResidenceValue: ', countryOfResidenceValue);
        console.log('onSubmit selectedState: ', selectedState);
        console.log('onSubmit accountType: ', accountType);
        console.log('onSubmit language: ', language);
        console.log(
          'onSubmit getCountryName(countryData, countryOfResidenceValue): ',
          getCountryName(countryData, countryOfResidenceValue)
        );

        TriggerGoogleAnalyticsTag(select, accountTypeCORGA, {
          COR: getCountryName(countryData, countryOfResidenceValue)
        });

        selectedState &&
          TriggerGoogleAnalyticsTag(select, accountTypeStateGA, {
            state: selectedState
          });

        TriggerGoogleAnalyticsTag(select, accountTypeLanguageGA, {
          language: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
        });

        navigateToRegistrationPortal();
      } else {
        setShouldShowServiceWarning(true);
      }
    } else {
      navigateToCFXPortal();
    }
  };

  const IndividualAccountTypeSelectedView: JSX.Element = (
    <>
      <ControllerElementWrapperContainer
        layout={layout}
        showStateDropDown={showStateDropDown}
        showCFSBInformationBlurb={showCFSBInformationBlurb}
      >
        <RPText variant={'body1'} type={'bold'} text={countryOfResidenceLabel} />
        <ControllerElementWrapper
          name="countryOfResidence"
          control={control}
          value={countryOfResidenceValue}
          placeholderLabel={corPlaceHolderText}
          component={RPSelectNew}
          size="normal"
          type="country"
          handleChange={(value: string) => handleCORChange(value)}
          showMaxOptions={!isMobileLayoutApplicable(layout)}
        />
      </ControllerElementWrapperContainer>
      <Collapse easing="300" in={showStateDropDown}>
        {showStateDropDown && (
          <ControllerElementWrapper
            name="stateDropdown"
            control={control}
            value={selectedState}
            placeholderLabel={statePlaceHolderText}
            component={RPSelectNew}
            size="normal"
            type="state"
            options={stateDropdownOptions}
            handleChange={(value: string) => handleStateChange(value)}
            showMaxOptions={!isMobileLayoutApplicable(layout)}
          />
        )}
      </Collapse>
      {/*<Collapse easing="300" in={showCFSBInformationBlurb}>*/}
      {showCFSBInformationBlurb && <RPInformationalBlurb contentArray={InformationalBlurbContentArray} />}
      {/*</Collapse>*/}
    </>
  );

  const AccountTypeSelectionView: JSX.Element = (
    <>
      <StyledHeading layout={layout} variant={headingTextVariant} type={'bold'} text={headingText} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFRPRadioListButton
          defaultValue={null}
          control={control}
          label={individualOptionLabelText}
          name="accountType"
          value="individual"
          icon={
            <AccountTypeIconContainer>
              <LandingPageIndividualIcon />
            </AccountTypeIconContainer>
          }
          isChecked={accountType === AccountTypes.individual}
          handleChange={handleAccountTypeChange}
          variantType="oneLineSmall"
        />
        <RHFRPRadioListButton
          defaultValue={null}
          control={control}
          label={businessOptionLabelText}
          name="accountType"
          value="business"
          icon={
            <AccountTypeIconContainer>
              <LandingPageBusinessIcon />
            </AccountTypeIconContainer>
          }
          isChecked={accountType === AccountTypes.business}
          handleChange={handleAccountTypeChange}
          variantType="oneLineSmall"
        />
        {isIndividualAccount && IndividualAccountTypeSelectedView}

        <RPButton
          size={buttonSize}
          type="submit"
          disabled={!isValid}
          name={submitButtonText}
          data-testid={'rp-open-free-account-button'}
        >
          {submitButtonText}
        </RPButton>
      </form>
    </>
  );

  const PartialServiceView: JSX.Element = (
    <PartialService
      selectedState={getStateName(selectedState, stateDropdownOptions)}
      handleClose={partialServiceBtnClickHandler}
      handleBack={partialServiceBackBtnHandler}
      size={isMobileLayout ? 'small' : 'normal'}
      isFromLandingOrRegister={true}
    />
  );

  const NoServiceView: JSX.Element = (
    <NoService
      selectedState={getStateName(selectedState, stateDropdownOptions)}
      handleClose={noServiceBtnClickHandler}
      size={isMobileLayout ? 'small' : 'normal'}
      selectedCountry={countryOfResidenceValue}
      isFromLandingOrRegister={true}
    />
  );

  const CFSBNoServiceView: JSX.Element = (
    <CFSBNoService
      size={isDesktopLayout ? 'normal' : 'small'}
      handleButtonClick={navigateToRegistrationPortal}
      handleBack={resetShowServiceWarningState}
    />
  );

  console.log('Register shouldShowServiceWarning: ', shouldShowServiceWarning);
  console.log('Register AccountTypeSelectionView: ', AccountTypeSelectionView);
  console.log('Register cdLicenseStatus: ', cdLicenseStatus);
  console.log(
    'Register CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!): ',
    CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!)
  );
  console.log('Register isCFSBEnabled: ', isCFSBEnabled);

  const currentView: JSX.Element = !shouldShowServiceWarning
    ? AccountTypeSelectionView
    : cdLicenseStatus === 1
    ? PartialServiceView
    : CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!) && isCFSBEnabled
    ? CFSBNoServiceView
    : NoServiceView;

  return (
    <SplitLayout isRegisterPage={true} layoutVariant={'full'}>
      <RPConfirmationModal
        isOpen={isZARModalOpen}
        handleClose={() => setIsZARModalOpen(false)}
        message={ZARMessage}
        additionMessage={ZARAdditionalMessage}
        primaryBtnLabel={ZARPrimaryBtnLabel}
        handlePrimaryBtnClick={navigateToCDSA}
        secondaryBtnLabel={ZARSecondaryBtnLabel}
        handleSecondaryBtnClick={() => {
          setIsZARModalOpen(false);
        }}
        modalVariant="ZAR"
        size={isMobileLayout ? 'small' : 'normal'}
      />
      <Container layout={layout}>{currentView}</Container>
    </SplitLayout>
  );
};

export default Register;
