import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Control, UseFormWatch } from 'react-hook-form';
import Box from '@mui/material/Box';

// Contexts
import { LayoutContext, LanguageContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../../atoms/RPText';
import RPInput from '../../../../atoms/RPInput';
import ControllerElementWrapper from '../../../../organisms/ControllerWrapper/ControllerWrapper';
import EmploymentFields from '../EmploymentFields';
// Types
import { HexColour } from '../../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../../core/types/LayoutProps';
import { EnterDetailsType } from '../../../../../core/types/RegistrationStepForm';
// RTK Slice
import { getRegistrationDetails } from '../../../../../redux/modules/registrationDetailsSlice';
// Utils
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { REGISTRATION_DEFINITIONS } from '../../RegistrationDefinitions';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Feature Flag
import { featureFlags } from '../../../../../core/FeatureFlags/FeatureFlags';

interface StyledContainerProps extends LayoutProps {}

const Container = styled('div')<StyledContainerProps>(({ theme, layout }) => {
  const {
    colours: {
      backgrounds: { background }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    backgroundColor: background,
    padding: isMobileLayout ? '15px' : '20px'
  };
});

const CheckIdDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '20px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledLabel = styled(RPText)(() => {
  return {
    marginBottom: '20px'
  };
});

interface USADetailsProps {
  control: Control<EnterDetailsType, EnterDetailsType>;
  watch: UseFormWatch<EnterDetailsType>;
  getFieldHeading: (labelText: string) => JSX.Element;
  employmentStatus: string;
  handleEmploymentStatusChange: (value: string) => void;
  showEmploymentIndustryDropdown: boolean;
  employmentIndustry: string;
}

interface FormWrapperProps {
  shouldShowEmploymentFields: boolean;
  showEmploymentIndustryDropdown: boolean;
}

const FormWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldShowEmploymentFields' && prop !== 'showEmploymentIndustryDropdown'
})<FormWrapperProps>(({ shouldShowEmploymentFields, showEmploymentIndustryDropdown }) => {
  return {
    '> p ': {
      // Occupation or employment fields and SSN Headings
      marginBottom: 10,

      ...(shouldShowEmploymentFields && {
        ':nth-of-type(1)': {
          marginBottom: 15
        }
      }),

      // Check ID Text
      ':nth-of-type(3)': {
        marginBottom: 0
      }
    },
    // Occupation or employment fields & SSN Input Fields
    '> div': {
      marginBottom: 20,

      ...(shouldShowEmploymentFields && {
        ...(showEmploymentIndustryDropdown
          ? {
              ':nth-of-type(1)': {
                marginBottom: 15
              }
            }
          : {
              ':nth-of-type(2)': {
                marginBottom: 0
              }
            })
      })
    }
  };
});

const {
  enterDetails: { showEmploymentFields }
} = featureFlags;

const USADetails: FC<USADetailsProps> = (props: USADetailsProps) => {
  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { usa, occupation } = useSelector(getRegistrationDetails);

  const {
    control,
    watch,
    getFieldHeading,
    employmentStatus,
    handleEmploymentStatusChange,
    showEmploymentIndustryDropdown,
    employmentIndustry
  } = props;

  const checkIdentityDesc: string = translate('registration.enterDetails.usa.checkIdentityDesc');
  const occupationHeading: string = translate('registration.enterDetails.usa.occupation.heading');
  const occupationLabel: string = translate('registration.enterDetails.usa.occupation.label');
  const socialSecurityNumberHeading: string = translate('registration.enterDetails.usa.socialSecurityNumber.heading');
  const socialSecurityNumberLabel: string = translate(
    isMobileLayoutApplicable(layout)
      ? 'registration.enterDetails.usa.socialSecurityNumber.shortLabel'
      : 'registration.enterDetails.usa.socialSecurityNumber.label'
  );

  const {
    enterDetails: {
      occupation: { name: occupationFieldName },
      usa: {
        socialSecurityNumber: { name: socialSecurityNumberFieldName }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const { occupation: occupationValue } = watch();
  const {
    eventAction: { select },
    enterYourDetails: {
      select: { occupation: occupationGA }
    }
  } = GoogleAnalyticsTagsMapping;

  const handleOccupationBlur = () => {
    TriggerGoogleAnalyticsTag(select, occupationGA, {
      occupation: occupationValue
    });
  };

  return (
    <Container layout={layout}>
      <FormWrapper
        shouldShowEmploymentFields={showEmploymentFields}
        showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
      >
        {!showEmploymentFields ? (
          <>
            {/* Your occupation */}
            <StyledLabel variant="body1" type="medium" text={occupationHeading} />
            <ControllerElementWrapper
              name={occupationFieldName}
              control={control}
              defaultValue={occupation}
              placeholderLabel={occupationLabel}
              component={RPInput}
              handleBlur={handleOccupationBlur}
            />
          </>
        ) : (
          <EmploymentFields
            getFieldHeading={getFieldHeading}
            control={control}
            employmentStatus={employmentStatus}
            handleEmploymentStatusChange={handleEmploymentStatusChange}
            showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
            employmentIndustry={employmentIndustry}
          />
        )}

        {/* Your choice of ID */}
        <StyledLabel variant="body1" type="medium" text={socialSecurityNumberHeading} />

        {/* Social security number */}
        <ControllerElementWrapper
          name={socialSecurityNumberFieldName}
          control={control}
          defaultValue={usa?.socialSecurityNumber || ''}
          placeholderLabel={socialSecurityNumberLabel}
          component={RPInput}
          type="password"
          isCoralogixMasked={true}
        />
        <CheckIdDescription variant="body2" type="normal" text={checkIdentityDesc} />
      </FormWrapper>
    </Container>
  );
};

export default USADetails;
