import axios, { AxiosResponse } from 'axios';
import GenerateNewTokenAfterApiFail from '../../core/utils/GenerateNewToken';

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ObjectMapping = { [key: string]: string };

const SERVICE_TO_URL_MAPPING: ObjectMapping = {
  addEnquiryLead: '/registration-service/addEnquiryLead',
  addRegistrationLead: '/registration-service/v1/addRegistrationLead',
  addRegistrationLeadV2: '/registration-service/v2/addRegistrationLead',
  checkDuplicateAccount: '/registration-service/checkDuplicateAccount',
  checkDuplicateAccountAdvanced: '/registration-service/checkDuplicateAccountAdvanced',
  checkOTP: '/communication-service/checkOTP',
  generateOTP: '/communication-service/generateOtp',
  getAdditionalInfoFields: '/registration-service/getAddinationalInfoFields/{countryCode}',
  getComplianceAndAccountStatus: '/registration-service/getComplianceAndAccountStatus',
  getCountryData: '/reference-data/country/{orgCodeID}/{localeId}',
  getCountryDataV1: '/reference-data/v1/country/{orgCodeID}/{localeId}',
  getCountryDataV2: '/reference-data/v2/country/{orgCodeID}/{localeId}',
  getCountryStateData: '/reference-data/country-state/{orgCode}/{state}/{localeId}',
  getCurrencyData: '/reference-data/currencies/{orgCode}/{accountType}',
  getCustomerDetailsByEmailToken: '/registration-service/token/{emailToken}',
  getEtaData: '/reference-data/estimated-transfer-amounts/{orgCode}/{localeId}',
  getLocationLookup: '/reference-data/location-lookup',
  getProofOfAddress: '/reference-data/proof-of-address/{legalEntity}/locale/{locale}',
  getProofOfIdentity: '/reference-data/proof-of-identity/{legalEntity}/locale/{locale}',
  mobileDownloadSMS: '/communication-service/mobileDownloadSms',
  saveDocuments:
    '/registration-service/saveDocuments?crmAccountId={crmAccountID}&crmContactId={crmContactID}&source={source}&orgCode={orgCode}&email={email}', //SAVE_DOCUMENT_URL
  updateLead: '/registration-service/v1/updateLead',
  verifyEtvExist: '/registration-service/verifyEtvExist',
  generateSDKToken: '/v3.6/sdk-token/create',
  createBiometricCheck: '/v3.6/applicants/checks',
  getEmploymentStatusData: '/reference-data/employment-status/{orgCodeID}/{localeId}',
  getEmploymentIndustryData: '/reference-data/employment-industry/{orgCodeID}/{localeId}'
};

const getURLForService = (type: string, dataToInsert: any) => {
  let currentServiceURL: string = SERVICE_TO_URL_MAPPING[type];

  if (dataToInsert) {
    for (const key in dataToInsert) {
      currentServiceURL = currentServiceURL.replace(`{${key}}`, dataToInsert[key]);
    }
  }

  return currentServiceURL;
};

const axiosWrapper = async (type: string, method: MethodType, thunkAPI: any, payload?: any, dataToInsert?: any) => {
  const accessToken: string = localStorage.getItem('accessToken') || '';
  const hasPayload: boolean = payload && (method === 'POST' || method === 'PUT');
  const currentURL: string = getURLForService(type, dataToInsert);

  const response: Promise<AxiosResponse> = new Promise((resolve, reject) => {
    axios({
      url: currentURL,
      method,
      headers: {
        'Content-Type': 'application/json',
        accept: '*/*',
        Authorization: `Bearer ${accessToken}`
      },
      ...(hasPayload && {
        data: payload
      })
    })
      .then((apiResponse) => {
        console.log('Axios then called');
        resolve(apiResponse);
      })
      .catch(async (apiError) => {
        if (apiError.response.status === 401) {
          await GenerateNewTokenAfterApiFail(thunkAPI)
            .then(async (generateTokenResponse: any) => {
              console.log('GenerateNewTokenAfterApiFail success', generateTokenResponse);
              if (generateTokenResponse?.success) {
                const retryResponse = await axiosWrapper(type, method, thunkAPI, payload, dataToInsert);
                resolve(retryResponse);
              }
            })
            .catch((generateTokenError) => {
              console.log('GenerateNewTokenAfterApiFail fail', generateTokenError.response);
              reject(generateTokenError);
            });
        } else {
          reject(apiError);
        }
      });
  });

  return response;
};

export default axiosWrapper;